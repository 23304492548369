export const filter = {
    id: "",
    debt_type: "",
    total_price: "",
    selected_patient: "",
    paid_price: "",
    partner_clinic_id: "",
    partner_doctor_id: "",
    doctor_id: "",
    created_at: "",
};
