import { actions } from './citizenship/actions'
import { getters } from './citizenship/getters'
import { state } from './citizenship/state'
import { mutations } from './citizenship/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
