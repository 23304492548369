import Layout from "@/layouts";

const documentType = {
  path: "/paymentBonus",
  component: Layout,
  name: "paymentBonus",
  redirect: "/paymentBonus/index",
  children: [
    {
      path: "/paymentBonus/index",
      component: () => import("@/views/paymentBonus/index"),
      name: "paymentBonus.index",
      meta: { title: "" },
    },
  ],
};

export default documentType;
