import { i18n } from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: '№',
    sortable: true,
    column: 'id'
  },
  name: {
    show: true,
    title: i18n.t('message.name'),
    sortable: true,
    column: 'name'
  },
  branch_id: {
    show: true,
    title: i18n.t('message.branch'),
    sortable: true,
    column: 'branch_id'
 },
 created_at: {
    show: true,
    title: i18n.t('message.date'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
    show: false,
    title: i18n.t('message.update_date'),
    sortable: true,
    column: 'updated_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }
};