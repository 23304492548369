export const rules = {
  name: [
    { required: true, message: 'Пожалуйста введите наименование', trigger: 'blur' }
  ],
  full_name: [
    { required: true, message: 'Пожалуйста введите полное наименование', trigger: 'blur' }
  ],
  type: [
    { required: true, message: 'Пожалуйста выберите тип', trigger: 'blue' }
  ]
};