import { filter } from './properties/filter'
import {model} from "./properties/model";
import {rules} from "./properties/rules";

export const state = {
    list: [],
    model: JSON.parse( JSON.stringify( model )),
    filter: filter,
    rules: rules
};
