import { actions } from './city/actions'
import { getters } from './city/getters'
import { state } from './city/state'
import { mutations } from './city/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
