import { actions } from './rooms/actions'
import { mutations } from './rooms/mutations'
import { getters } from './rooms/getters'
import { state } from './rooms/state'
export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
