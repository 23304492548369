<template>
  <div>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <table class="table table-striped table-bordered mt-4">
          <thead>
            <tr>
              <th>{{ $t("message.pp") }}</th>
              <th class="wi-30">{{ $t("message.name") }}</th>
              <th class="wi-7">{{ $t("message.price2") }}</th>
              <th class="wi-30">{{ $t("message.doctor_0") }}</th>
              <th class="wi-5">{{ $t("message.discount_percent") }}</th>
              <th class="wi-7">{{ $t("message.discount_price") }}</th>
              <th class="wi-7">{{ $t("message.price2") }}</th>
              <th class="wi-7">{{ $t("message.delete") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ser, index) in items" :key="'order-create-in-header' + index">
              <td>{{ index + 1 }}</td>
              <td>
                {{ ser.name }}
              </td>
              <td>
                {{ ser.price | formatMoney }}
              </td>
              <td>
                <el-select
                    filterable
                    clearable
                    :value="ser.doctor_id"
                    @change="editRow(ser, $event, 'doctor_id')"
                    :placeholder="$t('message.user')">
                  <el-option
                      v-for="item in users"
                      :key="item.id"
                      :label="
                        item.name +
                        ' ' +
                        (item.surname ? item.surname : '') +
                        ' ' +
                        (item.patronymic ? item.patronymic : '')
                      "
                      :value="item.id"
                  ></el-option>
                </el-select>
              </td>
              <td>
                <el-input-number :disabled="disableDiscount()" type="number" size="small"
                  @change="editRow(ser, $event, 'discount_price')" controls-position="right"
                  :value="discountService(ser.discount_price)" :min="0" :max="100"></el-input-number>
              </td>
              <td>
                <div v-if="!ser.discount_price || ser.discount_price == null">
                  <crm-price-input @input="editRow(ser, $event, 'price_of_discount')" v-model="ser.price_of_discount"
                    :old="ser.price_of_discount" size="mini">
                  </crm-price-input>
                </div>
                <div v-else>
                  {{
                    Math.round(
                      (discountService(ser.discount_price) *
                        ser.price *
                        ser.count) /
                      100
                    ) | formatMoney
                  }}
                </div>
              </td>
              <td>
                <span v-if="ser.discount_price">
                  {{
                    Math.round(
                      ser.count *
                      expressPrice(ser.is_express) *
                      (ser.price -
                        (ser.price *
                          discountService(ser.discount_price)) /
                        100)
                    ) | formatMoney
                  }}
                </span>
                <span v-else>
                  {{
                    Math.round(
                      ser.count * expressPrice(ser.is_express) * ser.price -
                      ser.price_of_discount
                    ) | formatMoney
                  }}
                </span>
              </td>
              <td>
                <el-button class="d-block" type="danger" icon="el-icon-delete" circle
                  @click.native.prevent="deleteRow(ser)">
                </el-button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <th colspan="5">{{ $t("message.total_0") }}</th>
            <th class="w-7">{{ totalDiscount | formatMoney }}</th>
            <th colspan="2">{{ Math.round(totalAmount) | formatMoney }}</th>
          </tfoot>
        </table>
      </el-col>
    </el-row>

    <!-- Доп.поля -->
    <el-collapse v-model="activeNames" @change="handleCollapseChange" >
      <el-collapse-item title="Доп.поля" name="1">
        <el-form ref="form" status-icon :model="form" :rules="rules" class="dop-items">
          <el-form-item>
            <div class="title_cet">{{ $t("message.diagnos") }}</div>
            <el-input v-model="form.diagnos" :placeholder="$t('message.diagnos')"></el-input>
          </el-form-item>

          <el-form-item>
            <div class="title_cet">
              {{ $t("message.secondary_doctor") }}
            </div>
            <users @parent-event="parentEvent" :specialty_id="specialty_id" v-model="form.secondary_doctor_id"
              :doctor_id="form.secondary_doctor_id"></users>
          </el-form-item>

<!--          <el-form-item>-->
<!--            <div class="title_cet">{{ $t("message.insurance_company") }}</div>-->
<!--            <el-select v-model="form.insurance_company_id" filterable clearable-->
<!--              :placeholder="$t('message.insurance_company')">-->
<!--              <el-option v-for="item in insuranceCompanies" :key="item.id" :label="item.name" :value="item.id">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->

<!--          <el-form-item>-->
<!--            <div class="title_cet">{{ $t("message.insurance_number") }}</div>-->
<!--            <el-input v-model="form.insurance_number" :placeholder="$t('message.insurance_number')"></el-input>-->
<!--          </el-form-item>-->

          <el-form-item>
            <div class="title_cet">{{ $t("message.department") }}</div>
            <el-select v-model="form.department_id" filterable clearable :placeholder="$t('message.department')">
              <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <div class="title_cet">{{ $t("message.partner_doctor") }}</div>
            <partner-doctors :size="'large'" v-model="form.partner_doctor_id"
              :partner_clinic_id="form.partner_clinic_id"></partner-doctors>
          </el-form-item>

          <el-form-item v-for="orderColumn in orderColumns" :key="orderColumn.id">
            <div class="title_cet">{{ orderColumn.name }}</div>
            <el-input v-model="attributes[orderColumn.name]"></el-input>
          </el-form-item>
        </el-form>

        <el-row :gutter="20">
          <el-col :sm="12">
            <button class="add_pats" @click="drawerPartnerDoctor = true">
              {{ $t("message.add_new_partner_doctor") }}
            </button>
          </el-col>
          <el-col :sm="12">
            <button class="add_pats" @click="drawerDepartment = true">
              {{ $t("message.add_new_department") }}
            </button>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>

    <el-drawer class="popups_title" :append-to-body="true" :visible.sync="drawerCreatePatient" direction="rtl"
      size="60%" :wrapperClosable="false">
      <CreatePatient @c-close="closeDrawerPatient" :reopened="drawerCreatePatient" drawer="drawerCreatePatient">
      </CreatePatient>
    </el-drawer>

    <el-drawer class="popups_title" @open="reopenUpdate = true" @close="reopenUpdate = false" :append-to-body="true"
      :visible.sync="drawerUpdatePatient" direction="rtl" size="60%" :wrapperClosable="false">
      <UpdatePatient @c-close="closeDrawerUpdatePatient" :reloadModel="reopenUpdate" :patient="selectedPatient"
        :open="drawerUpdatePatient" drawer="drawerUpdatePatient"></UpdatePatient>
    </el-drawer>

    <el-drawer class="popups_title" :append-to-body="true" :visible.sync="drawerPartnerDoctor" direction="rtl"
      size="80%" :wrapperClosable="false">
      <AddPartnerDoctor @c-close="closeDrawerAddPartnerDoctor"></AddPartnerDoctor>
    </el-drawer>

    <el-drawer class="popups_title" :append-to-body="true" :visible.sync="drawerPartnerClinic" direction="rtl"
      size="80%" :wrapperClosable="false">
      <AddPartnerClinic @c-close="closeDrawerAddPartnerDoctor"></AddPartnerClinic>
    </el-drawer>

    <el-drawer class="popups_title" :append-to-body="true" :visible.sync="drawerDepartment" direction="rtl" size="50%"
      :wrapperClosable="false">
      <CreateDepartment @c-close="closeDrawerAddPartnerDoctor"></CreateDepartment>
    </el-drawer>

    <el-drawer class="popups_title" :wrapperClosable="false" :append-to-body="true" :visible.sync="drawerPatientService"
      direction="rtl" size="85%" :ref="serviceDrawer.create.name" @opened="drawerOpened(serviceDrawer.create.component)"
      @closed="drawerClosed(serviceDrawer.create.component)">
      <CreatePatientService :ref="serviceDrawer.create.component" :driver="drawerPatientService" :doctor_id="doctor_id"
        @items="itemsChanged" @owner_branch_id="getOwnerBranch" @c-close="closeDrawerPatientService">
      </CreatePatientService>
    </el-drawer>
    
    <el-drawer title="Изменить тег" :visible.sync="drawerShow" size="70%" :wrapperClosable="false" :drawer="drawerShow"
      @open="reopenShow = true" :append-to-body="true" @close="reopenShow = false">
      <div>
        <crm-show :reloadModel="reopenShow" @open="reopenShow = true" @c-close="reloadIfChanged('drawerShow')"
          @closed="emptyModel"></crm-show>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import order from "@/utils/mixins/order";
import users from "@/components/selects/user_list";
import CrmShow from "./crm-show";
import partnerDoctors from "@/components/inventory-select/partner-doctor";
import drawer from "@/utils/mixins/drawer";
import CrmPriceInput from "@/components/crm/include/crm-price-input";

export default {
  mixins: [order, drawer],
  props: ["currentPatient", 'items'],
  components: {
    CrmShow,
    users,
    partnerDoctors,
    CrmPriceInput,
  },
  data() {
    return {
      RoomName: "",
      RoomNumber: "",
      old_save: false,
      specialty_id: null,
      old_order: {},
      activeNames: [],
      infoOldSelected: false,
      drawerShow: false,
      reopenShow: false,
      saveBtn: true,
      printModel: {},
      owner_branch_id: null,
      express_check: false,
      reopenUpdate: false,
      reloadList: false,
      room: null,
      doctor_id: null,
      drawerDepartment: false,
      attributes: {},
      searchServices: "",
      localItems: [...this.items],
      defaultProps: {
        children: "children",
        label: "label",
        price: "price",
      },
      serviceDrawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
      },
    };
  },
  watch: {
    searchServices(val) {
      this.$refs.items.filter(val);
    },
    search() {
      this.infoOldSelected = false;
    },
    open: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.setNewOrderData();
        }
      },
      deep: true,
      immediate: true,
    },
    // conditions: {
    //   handler: function (newVal, oldVal) {
    //     if (newVal) {
    //       this.form.condition_id = this.conditions[0].id;
    //     }
    //   },
    //   deep: true,
    // },
  },
  computed: {
    ...mapGetters({
      orderColumns: "orderColumn/list",
      insuranceCompanies: "insuranceCompany/list",
      contractServiceList: "contracts/contract_services",
      // model: "orders/relation_model",
      specialties: "specialties/list",
      workTimes: "workTimes/inventory",
      rules: "orders/rules",
      express: "expresses/list",
      users: "users/doctor_list",
    }),
  },
  mounted() {
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getInsuranceCompany: "insuranceCompany/index",
      getOrderColumns: "orderColumn/index",
      updateSetlist: "services/updateSetlist",
      setworkTimes: "workTimes/inventory",
      loadExpress: "expresses/index",
      editService: "services/show",
      getSpecialties: "specialties/index",
      // servicesRealtionList: "services/relationList",
      contractServices: "contracts/contractServices",
      discountPatient: "discountPatients/show",
      // empty_conclusions: "conclusions/empty",
      showModel: "conclusions/show",
      save: "orders/store",
      update: "orders/update",
      saveList: "services/setlist",
      getInventory: "users/doctor_list",
    }),
    getOwnerBranch(val) {
      this.owner_branch_id = val;
    },
    filterDepartment(val) {
      const query = { partner_clinic_id: val };
      this.loadDepartments(query).then((res) => {
        if (!res.data.departments.data.length) this.form.department_id = null;
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    selectDoctorService(val) {
      let item = this.$refs.items.getCheckedNodes();
      let arr = [];
      this.emptyList();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0) {
            arr.push(element);
          }
        }
      }
      this.updateSetlist(JSON.parse(JSON.stringify(arr))).then((res) => {
        this.updateItems(this.list);
      });
    },
    addClass() {
      this.infoOldSelected = true;
    },
    parentEvent(value) {
      this.servicesFreeList({
        selected_relation: true,
        only_child: true,
        doctor_id: value,
      });
      this.emptyList();
      // this.items = [];
      this.doctor_id = value;
    },
    async show(model) {
      this.$loadingCursor("wait");
      await this.showModel(model.id)
        .then((res) => {
          this.$loadingCursor("default");
          this.drawerShow = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    disableDiscount() {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        return true;
      } else return false;
    },

    expressPrice(is_express) {
      if (is_express) {
        return 1 + this.express[0].percent / 100;
      } else {
        return 1;
      }
    },
    discountService(sevice_discount) {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        this.items.forEach((element) => {
          element.discount_price = this.discountPatients[0].discount;
        });
        return this.discountPatients[0].discount;
      } else return sevice_discount;
    },

    itemsChanged(val) {
      this.updateItems(val);
    },
    fetchData() {},
    setNewOrderData() {
      this.form = JSON.parse(JSON.stringify(this.model));
      this.form.day = new Date();
    },
    submit(close) {
      this.changeWaiting(true);
      this.form.patient_id = this.user.id;
      this.form.services = this.items;
      this.form.owner_branch_id = this.owner_branch_id;
      this.form.order_attributes = this.attributes;
      this.save(this.form)
        .then((res) => {
          this.old_save = true;
          this.old_order = res.data.order;
          this.changeWaiting();
          this.saveBtn = false;
          this.printModel = res.data ? res.data.order : null;
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },
    updatingOrder(close) {
      this.changeWaiting(true);
      this.form.id = this.old_order.id;
      this.form.patient_id = this.user.id;
      this.form.services = this.items;

      this.update(this.form)
        .then((res) => {
          this.changeWaiting();
          this.saveBtn = false;
          this.printModel = res.data ? res.data.order : null;
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },

    tabEvent(event) {
      if (
        event.name == "second" &&
        this.form.contract_id &&
        this.contractServiceList.length == 0
      ) {
        this.contractServices({
          id: this.form.contract_id,
          contract_service: true,
        })
          .then((res) => { })
          .catch((err) => { });
      }
    },
    AddContactServices() {
      this.saveList(this.contractServiceList.services)
        .then((res) => { })
        .catch((err) => { });
    },
    closeForm() {
      this.old_order = {};
      this.old_save = false;
      this.empty();
      this.$emit("c-close", { reload: false, drawer: this.drawer });
    },
    resetForm() {
      this.setNewOrderData();
      this.user = {};
      this.noUser();
      this.emptySearch();
      this.emptyList();
      this.old_order = {};
      this.old_save = false;
      this.search = "";
      this.saveBtn = true;
      this.latestServices.order_services = [];
      this.discountPatients = [];
      this.items = [];
    },
    empty() {
      this.activeTab = "first";
      this.emptyList();
      this.emptySearch();
      this.noUser();
      this.user = null;
      this.patientServiceId = "";
      this.search = "";
      this.saveBtn = true;
      this.latestServices.order_services = [];
      this.discountPatients = [];
      this.items = [];
    },
    listChanged() {
      this.reloadList = true;
    },
    parent() {
      return this.$parent.$parent;
    },
    emptyModel() {
      this.empty_conclusions();
    },
    updateItems(val) {
      this.localItems = JSON.parse(JSON.stringify(val));

      this.$emit('update:items', this.localItems);
    },
    editRow(row, event, key) {
      let data = {
        id: row.id,
        position: row.position,
        value: event,
        key: key
      }
      this.updateSelectedListItem(data)
          .then(res => {
            this.updateItems(this.list);
          }).catch(err => { })
    },
    handleCollapseChange(activeNames) {
      if (activeNames[0] === '1') {
        if (this.workTimes && this.workTimes.length === 0) this.setworkTimes();
        // if (this.conditions && this.conditions.length === 0) this.loadConditions();
        const order_column_query = {
          get_actives: true,
          place: "is_register",
          per_page: 1000000,
        };
        if (this.orderColumns && this.orderColumns.length === 0) this.getOrderColumns(order_column_query);
        // if (this.insuranceCompanies && this.insuranceCompanies.length === 0) this.getInsuranceCompany();
        if (this.specialties && this.specialties.length === 0) this.getSpecialties();
        if (this.express && this.express.length === 0) this.loadExpress();
        if (this.users && this.users.length === 0) this.getInventory();
      }
    }
  },
};
</script>
<style lang="scss">
.tableKa .el-table_1_column_7 .cell {
  display: block !important;
}

.bemor_uslugalar {
  text-decoration: none;
  list-style: none;
  margin: 0px;
  padding: 0px;

  li {
    border-bottom: 1px solid #dcdfe6;
    line-height: 19px;
    padding: 5px 0;
  }

  div {
    font-weight: 600;
  }

  span {
    text-align: right;
    width: 100%;
    display: block;
  }

  .col__red span {
    color: red;
  }
}

.ow_hiddin {
  height: 50vh;
  overflow-y: scroll;
}

.ow_hiddin::-webkit-scrollbar {
  width: 8px;
}

.ow_hiddin::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #fff;
}

.ow_hiddin::-webkit-scrollbar-thumb {
  background-color: #1f75a8;
  border-radius: 20px;
}

.my_add_pats {
  margin: 10px 0 20px 0;
}

.new_room {
  display: flex;
  width: 100%;
  align-items: center;
  height: 50px;
  /* background: #dcdfe6; */
  border-bottom: 1px solid #dcdfe6;
}

.room_number_title {
  margin-left: 10px;
  font-weight: 600;
}

.my-100 {
  .el-select {
    width: 100%;
  }

  .add_pats {
    margin-top: 0px;
  }
}
</style>