<template>
  <el-select
      v-model="selected"
      @input="dispatch"
      :placeholder="plc || 'Партнёр клиника'"
      filterable
      clearable
  >
    <el-option
        v-for="item in clinics"
        :key="'clinic_'+item.id"
        :label="item.name"
        :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["partner_clinic_id", "id", "open"],
  watch: {
    id: {
      handler: function (newValue) {
        this.selected = parseInt(newValue);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      search: '',
      selected: null,
    }
  },
  computed: {
    ...mapGetters({
      clinics: "partnerClinic/inventory_all",
    }),
    filtered: function () {
      console.log(this.clinics, 'filtered')
      return this.clinics;
    },
  },
  methods: {
    ...mapActions({
      getInventory: "partnerClinic/inventoryAll",
    }),
    async loadClinics() {
      await this.getInventory({column: "name", order: "asc"})
          .then(res => {
            console.log(this.clinics, 'clinics')

          });

    },
    setInitialSelected() {
      if (this.id) {
        this.selected = this.id;
        this.$emit("input", this.selected);
      }
    },
    dispatch(value) {
      this.$emit("input", value);
    }
  },
};
</script>