export const getters = {
    list: state => state.list,
    columns: state => state.columns,
    columnsNew: state => state.columnsNew,
    model: state => state.model,
    relation_model: state => state.relation_model,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort,
    rules: state => state.rules,
    prices: state => state.prices,
    patient_list: state => state.patient_list,
    informal_patient_list: state => state.informal_patient_list,
};
