import { i18n } from '@/utils/i18n';
export const columns = {
    ids: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    service_type_name: {
        show: false,
        title: i18n.t('message.service_type_name'),
        sortable: true,
        column: 'service_type_name'
    },
    service_type_id: {
        show: true,
        title: i18n.t('message.service_type_id'),
        sortable: true,
        column: 'service_type_id'
    },
    patient: {
        show: true,
        title: i18n.t('message.patient_name'),
        sortable: true,
        column: 'patient'
    },
    service: {
        show: true,
        title: i18n.t('message.service_name'),
        sortable: true,
        column: 'service'
    },
    created_at: {
        show: false,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at',
    },
    updated_at: {
        show: false,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'updated_at',
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};