import { model } from "./properties/model";
import { filter } from "./properties/filter";
import { sort } from "./../properties/sort";
import { pagination } from "./../properties/pagination";
import { i18n } from '@/utils/i18n';

export const mutations = {
    // SET_LIST: (state, facades) => (state.list = facades),
    SET_MODEL: (state, model) => {
        state.model.id = model.id;
        state.model.address = model.address;
        state.model.web = model.web;
        state.model.email = model.email;
        state.model.number = model.number;
        
        state.model = model;
        if (model.images) {
            state.fileList.push({
                url: process.env.VUE_APP_URL_DOCS + model.images[0].path,
                id: model.images[0].id,
                name: model.web
            });
        } else state.fileList = [];
    },
  
    SET_LIST: (state, states) => (state.list = states),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    SET_SORT: (state, sort) => (state.sort = sort),
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
        state.fileList = [];
    },
    // EMPTY_MODEL: (state) => {
    //     state.model = JSON.parse(JSON.stringify(model));
    // },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    UPDATE_LANG_COLUMN: (state) => {
      
            state.columns.id.title =  "№";
            state.columns.address.title =  i18n.t('message.address');
        
            state.columns.web.title =  i18n.t('message.website_name');
            state.columns.email.title =  i18n.t('message.email_address');
            state.columns.number.title =  i18n.t('message.phone_number');
            state.columns.created_at.title =  i18n.t('message.date');
            state.columns.updated_at.title =  i18n.t('message.update_date');
            state.columns.settings.title = i18n.t('message.settings');

    }
};