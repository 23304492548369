<template>
  <el-select
      v-model="selected"
      @input="dispatch"
      :placeholder="plc || 'Курьер'"
      filterable
      clearable
  >
    <el-option
        v-for="item in filtered"
        :key="item.id"
        :label="item.name"
        :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["partner_clinic_id", "id", "open"],
  async mounted() {
    await this.loadCouriers();
    this.setInitialSelected();
  },
  watch: {
    partner_clinic_id: {
      handler: async function () {
        await this.loadCouriers();
        this.selected = null;
        this.$emit("input", this.selected);
      },
    },
    id: {
      handler: function () {
        this.setInitialSelected();
      },
      deep: true,
      immediate: true,
    },
    open: {
      handler: async function (newValue) {
        if (newValue === true) {
          await this.loadCouriers();
          this.setInitialSelected();
        }
      }
    }
  },
  data() {
    return {
      search: '',
      selected: null,
      filtered: [],
    }
  },
  computed: {
    ...mapGetters({
      couriers: "couriers/inventory",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "couriers/inventory",
    }),
    async loadCouriers() {
      const couriers = await this.getInventory({
        column: "name",
        order: "asc",
        partner_clinic_id: this.partner_clinic_id
      });
      this.filtered = couriers.data.couriers.data;
    },
    setInitialSelected() {
      if (this.id) {
        this.selected = this.id;
        this.$emit("input", this.selected);
      }
    },
    dispatch(value) {
      this.$emit('input', value);
    }
  },
};
</script>
