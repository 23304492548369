import { actions } from './permission/actions'
import { mutations } from './permission/mutations'
import { getters } from './permission/getters'
import { state } from './permission/state'
export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
