export const model = {
  name: '',
  code: '',
  sku: '',
  price: '',
  currency_id: '',
  category_id: '',
  vat: '',
  description: '',
  measurement_id: '',
  secondary_measurements:[],
  old_secondary_measurements:[],
  new_secondary_measurements:[],
};