export const model = {
    id: null,
    detail: '', 
    dailyTreatmentNames: [{}],
    products: [{}],
    dailyTreatments:[{}],
    dailyTreatmentIndeces:[],
    created_at: '',
    updated_at: ''
};
