import request from './../utils/request'


export function index(params) {
    return request({
        url: '/PartnerDoctorReport',
        method: 'get',
        params
    })
}



export function show(params) {
    return request({
      url: `/PartnerDoctorReport/${params.id}`,
      method: 'get',
      params
    })
  }


