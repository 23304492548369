import request from "./../utils/request"

export function show(id) {
  return request({
    url: `/outsourcing/${id}`,
    method: "get",
  })
}

export function index(params) {
  return request({
    url: "/outsourcing",
    method: "get",
    params,
  })
}

export function sendToHistory(data) {
  return request({
    url: `/outsourcing/storeFile`,
    method: "POST",
    data,
  })
}

export function inventory(params) {
  return request({
    url: "/outsourcing/inventory",
    method: "get",
    params,
  })
}
export function store(data) {
  return request({
    url: "/outsourcing",
    method: "post",
    data,
  })
}

export function update(datas) {
  let data = datas.data
  let id = datas.id
  return request({
    url: `/outsourcing/${id}`,
    method: "put",
    data,
  })
}

export function destroy(id) {
  return request({
    url: `/outsourcing/${id}`,
    method: "delete",
  })
}