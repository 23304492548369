import Layout from '@/layouts'

const schedule = {
  path: '/hospitalPatient',
  component: Layout,
  name: 'hospitalPatient',
  redirect: '/patientHistory/index',
  children: [
    {
      path: '/patientHistory/index',
      component: () => import('@/views/patientHistory/index'),
      name: 'patientHistory.index',
      meta: {
        title: 'График'
      }
    }, 
    {
      path: '/patientRoom/main/:id',
      component: () => import('@/views/patientRoom/index'),
      name: 'patientRooms',
      meta: { title: 'Пациенты'}
    },
  ]
}

export default schedule