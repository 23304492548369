import { actions } from './injuryStatus/actions'
import { getters } from './injuryStatus/getters'
import { state } from './injuryStatus/state'
import { mutations } from './injuryStatus/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
