export const model = {
  id: null,
  name: '',
  code: '',
  sku: '',
  price: '',
  currency: null,
  category: null,
  vat: '',
  description: '',
  main_measurement: null,
  main_measurement: null,
  secondary_measurements: null,
  procurement_items: []
};