import { i18n } from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: '№',
    sortable: true,
    column: 'id'
  },
  number: {
    show: true,
    title: i18n.t('message.number_aggrement'),
    sortable: true,
    column: 'number'
  },
  begin_date: {
    show: true,
    title: i18n.t('message.date_aggrement'),
    sortable: true,
    column: 'begin_date'
  },
  supplier_id: {
    show: true,
    title: i18n.t('message.suppliers'),
    sortable: true,
    column: 'supplier_id'
  },
  status_id: {
    show: true,
    title: i18n.t('message.status'),
    sortable: true,
    column: 'status_id'
  },
  sum: {
    show: true,
    title: i18n.t('message.sum'),
    sortable: true,
    column: 'sum'
  },
  description: {
    show: true,
    title: i18n.t('message.description'),
    sortable: true,
    column: 'description'
  },
  created_at: {
    show: true,
    title: i18n.t('message.date'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
    show: true,
    title: i18n.t('message.update_date'),
    sortable: true,
    column: 'updated_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }
};