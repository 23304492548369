import request from "./../utils/request"

export function show(id) {
  return request({
    url: `/outsource-party/${id}`,
    method: "get",
  })
}

export function index(params) {
  return request({
    url: "/outsource-party",
    method: "get",
    params,
  })
}

export function inventory(params) {
  return request({
    url: "/outsource-party/inventory",
    method: "get",
    params,
  })
}
export function store(data) {
  return request({
    url: "/outsource-party",
    method: "post",
    data,
  })
}

export function update(data) {
  return request({
    url: `/outsource-party/${data.id}`,
    method: "put",
    data,
  })
}

export function destroy(id) {
  return request({
    url: `/outsource-party/${id}`,
    method: "delete",
  })
}