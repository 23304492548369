import { actions } from './patientImage/actions'
import { getters } from './patientImage/getters'
import { state } from './patientImage/state'
import { mutations } from './patientImage/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
