import request from '../utils/request'

export function index(params) {
    return request({
        url: '/PatientServiceReport',
        method: 'get',
        params
    })
}

export function show(params) {
    return request({
        url: `/PatientServiceReport/${params.serviceId}`,
        method: 'get',
        params
    })
}
