export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    ],
    city_id: [
        { required: true, message: 'Пожалуйста, выберите город', trigger: 'blur' },
    ],
    code: [
        { required: true, message: 'Пожалуйста, выберите код', trigger: 'blur' },
    ],
};
