import request from './../utils/request';

export function index(params) {
  return request({
    url: '/statuses',
    method: 'GET',
    params,
  });
}

export function show(id) {
  return request({
    url: '/statuses/' + id,
    method: 'GET',
  });
}

export function inventory(params) {
  return request({
    url: '/statuses/inventory',
    method: 'GET',
    params
  });
}

export function store(data) {
  return request({
    url: '/statuses',
    method: 'POST',
    data,
  });
}

export function update(data) {
  return request({
    url: '/statuses/' + data.id,
    method: 'put',
    data,
  });
}

export function destroy(id) {
  return request({
    url: '/statuses/' + id,
    method: 'DELETE',
  });
}
