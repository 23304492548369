import request from './../utils/request'


export function index(params) {
    return request({
        url: '/form-templates',
        method: 'get',
        params
    })
}

export function list(params) {
    return request({
        url: '/cons/list',
        method: 'get',
        params
    })
}

export function show(id) {
    return request({
        url: `/form-templates/${id}`,
        method: 'get'
    })
}

export function store(data) {
    return request({
        url: '/form-templates',
        method: 'post',
        data
    })
}

export function doctorTemplate(data) {
    return request({
        url: '/doctor-templates',
        method: 'post',
        data
    })
}

export function doctorTemplates(data) {
    return request({
        url: '/doctor-templates',
        method: 'get',
        data
    })
}

export function update(data) {
    return request({
        url: `/form-templates/${data.id}`,
        method: 'put',
        data
    })
}

export function destroy(id) {
    return request({
        url: `/form-templates/${id}`,
        method: 'delete',
    })
}