export const filter = {
    id: "",
    name: "",
    corps_id: "",
    floor_id: "",
    class_id: "",
    search: "",
    created_at: "",
    updated_at: "",
    color: "",
    from: "",
    till: "",
};
