import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: '№',
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    
    surname: {
        show: true,
        title: i18n.t('message.surname'),
        sortable: true,
        column: 'surname'
    },
    bonus_amount: {
        show: true,
        title: i18n.t('message.bonus_amount'),
        sortable: true,
        column: 'bonus_amount'
    },
    patronymic: {
        show: true,
        title: i18n.t('message.patronymic'),
        sortable: true,
        column: 'patronymic'
    },
    phone : {
        show: true,
        title: i18n.t('message.phone'),
        sortable: true,
        column: 'phone'
    },
    password : {
        show: true,
        title: i18n.t('message.password'),
        sortable: true,
        column: 'password'
    },
    gender: {
        show: false,
        title: i18n.t('message.gender'),
        sortable: false,
        column: 'gender'
    },
    
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },
};
