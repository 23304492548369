import {filter} from "./properties/filter";
import {model} from "./properties/model";

export const mutations = {
    SET_LIST: (state, schedules) => {
        state.list = schedules
    },
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_MODEL: (state,model) => {
        state.model.id = model.id;
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
    }
};
