import { i18n } from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id',
    },
    // image: {
    //     show: true,
    //     title: "Загрузить изображение",
    //     sortable: false,
    //     column: 'image'
    // },

    address: {
        show: true,
        title: i18n.t('message.address'),
        sortable: false,
        column: 'address'
    },

    web: {
        show: true,
        title: i18n.t('message.website_name'),
        sortable: false,
        column: 'web'
    },
    email: {
        show: true,
        title: i18n.t('message.email_address'),
        sortable: false,
        column: 'email'
    },
    number: {
        show: true,
        title: i18n.t('message.phone_number'),
        sortable: false,
        column: 'number'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title:i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },
};