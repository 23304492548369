import { actions } from './hospitalPatient/actions'
import { getters } from './hospitalPatient/getters'
import { state } from './hospitalPatient/state'
import { mutations } from './hospitalPatient/mutations'


export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
