import Layout from '@/layouts'

const doctorsRouter = {
  path: '/doctors',
  component: Layout,
  name: 'doctors',
  redirect: 'doctors',
  children: [
    {
      path: 'doctors',
      component: () => import('@/views/doctors/index'),
      name: 'doctors.index',
      meta: { title: 'doctors'}
    },
  ]
}

export default doctorsRouter