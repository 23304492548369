export const filter = {
    id: "",
    name: "",
    status: "",
    doctor_can: "",
    color: "",
    search: "",
    created_at: "",
    updated_at: "",
};
