import Layout from '@/layouts'

const specialty = {
  path: '/citizenship',
  component: Layout,
  name: 'citizenship',
  redirect: '/citizenship/index',
  children: [{
    path: '/citizenship/index',
    component: () => import('@/views/citizenship/index'),
    name: 'citizenship.index',
    meta: {
      title: 'Гражданство'
    }
  }, ]
}

export default specialty