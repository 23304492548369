import {
  index,
  show,
  store,
  update,
  destroy,
  inventory
} from '../../../api/process';

export const actions = {
  index({
    commit
  }, params = {}) {
    return new Promise((resolve, reject) => {
      index(params)
        .then(response => {
          commit('SET_LIST', response.data.data.processes.data);
          commit('UPDATE_PAGINATION', {
            key: 'total',
            value: response.data.data.processes.total
          });
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  show({
    commit,
  }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then(response => {
          commit('SET_MODEL', response.data.data.process);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  inventory({
    commit
  }, params = {}) {
    return new Promise((resolve, reject) => {
      inventory(params)
        .then(response => {
          commit('SET_INVENTORY', response.data.data.processes.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  store({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      store(payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  update({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      update(payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  destroy({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateSort({
    commit
  }, sort) {
    commit('SET_SORT', sort);
  },
  updateFilter({
    commit
  }, filter) {
    commit('SET_FILTER', JSON.parse(JSON.stringify(filter)));
  },
  updateColumn({
    commit
  }, column) {
    commit('UPDATE_COLUMN', column);
  },
  updatePagination({
    commit
  }, pagination) {
    commit('UPDATE_PAGINATION', pagination);
  },
  refreshData({
    commit
  }) {
    return new Promise((resolve, reject) => {
      commit('REFRESH');
      resolve();
    });
  },
  empty({
    commit
  }) {
    return new Promise((resolve, reject) => {
      commit('EMPTY_MODEL');
      resolve();
    });
  }
};