import request from '../utils/request'


export function index(params) {
  	return request({
  	  url: '/patientHistories',
  	  method: 'get',
        params
  	})
  }
  export function inventory(params) {
    return request({
      url: '/patientHistories/inventory',
      method: 'get',
      params
    })
  }
  export function getLastId(params) {
    return request({
      url: '/patientHistories/getLastId',
      method: 'get',
      params
    })
  } 

export function show(params) {
    return request({
      url: `/patientHistories/${params.id}`,
      method: 'get',
      params
    })
  }
  
  export function store(data) {
    return request({
      url: '/patientHistories',
      method: 'post',
      data
    })
  }

  export function createHospital(data) {
    return request({
      url: '/patientHistories/createHospital',
      method: 'post',
      data
    })
  }

  export function endHospitalization(data) {
    return request({
      url: '/patientHistories/endHospitalization',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/patientHistories/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/patientHistories/${id}`,
      method: 'delete',
    })
  }
