import { actions } from './nurceOffice/actions'
import { getters } from './nurceOffice/getters'
import { state } from './nurceOffice/state'
import { mutations } from './nurceOffice/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
