import Layout from '@/layouts'

const schedule = {
  path: '/neededPills',
  component: Layout,
  name: 'neededPills',
  redirect: '/neededPills/index',
  children: [{
    path: '/neededPills/index',
    component: () => import('@/views/neededPills/index'),
    name: 'neededPills.index',
    meta: {
      title: 'Кабинет медсестра '
    }
  }, ]
}

export default schedule