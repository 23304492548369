import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    user_id: {
        show: true,
        title: i18n.t('message.doctor'),
        sortable: false,
        column: 'user_id'
    },
    conclusion_id: {
        show: true,
        title:  i18n.t('message.conclusions'),
        sortable: false,
        column: 'conclusion_id'
    },
    patient_id: {
        show: true,
        title: i18n.t('message.patient'),
        sortable: false,
        column: 'patient_id'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
