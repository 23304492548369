import Layout from '@/layouts'

const hour = {
  path: '/defects',
  component: Layout,
  name: 'defects',
  redirect: '/defects/index',
  children: [{
    path: '/defects/index',
    component: () => import('@/views/defects/index'),
    name: 'defects.index',
    meta: {
      title: 'Времени'
    }
  }, ]
}

export default hour