<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.product')"
    filterable
    clearable
  >
    <el-option
      v-for="product in products"
      :key="product.id"
      :label="formatProductOption(product)"
      :value="product.id"
    />
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  props: ['warehouse_id'],
  mixins: [inventory],
  async mounted() {
    const query = {
      warehouse_id: this.warehouse_id ? this.warehouse_id : 1,
    };
    await this.getInventory(query);
  },
  computed: {
    ...mapGetters({
      products: 'warehouseProducts/inventory',
    }),
  },
  methods: {
    ...mapActions({
      getInventory: 'warehouseProducts/inventory',
    }),
    dispatch(productID) {
      this.$emit(
        'append',
        this.products.find((product) => product.id === productID)
      );
    },
    formatProductOption(product) {
      const seriesNumber = product.serial_number ? `Серия: ${product.serial_number}` : '';

      return `${product.product.name} - ${product.price} (Количество: ${product.available}) ${seriesNumber}`;    },
  },
};
</script>
