import { actions } from './couriers/actions'
import { getters } from './couriers/getters'
import { state } from './couriers/state'
import { mutations } from './couriers/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}