import { actions } from './measurements/actions'
import { getters } from './measurements/getters'
import { state } from './measurements/state'
import { mutations } from './measurements/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
