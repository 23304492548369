import Layout from '@/layouts'

const patientAge = {
    path: '/patientAge',
    component: Layout,
    name: 'patientAge',
    redirect: '/patientAge/index',
    children: [{
      path: '/patientAge/index',
      component: () => import('@/views/patientAge/index'),
      name: 'patientAge.index',
      meta: {
        title: 'График'
      }
    }, ]
  }
  

export default patientAge