import {sort} from "../properties/sort";
import {filter} from "./properties/filter";
import {pagination} from "../properties/pagination";
import {model} from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, orders) => {
        state.list = orders  
        let total = 0;
        let paid = 0;
        let refund = 0;
        for (const key in orders) {
            if (orders.hasOwnProperty(key)) {
                const element = orders[key];
                
                total += parseFloat(element.total_price)              
                for (const i in element.payment) {
                    if (element.payment.hasOwnProperty(i)) {
                        const payment = element.payment[i];
                        paid += parseFloat(payment.paid_price)    
                        for (const a in payment.transactions) {
                            const transactions = payment.transactions[a]
                            if(transactions.refund == true){
                                refund += parseFloat(transactions.price) 
                            }
                        }
                    }
                }
            }
        }
        let not_paid = total - paid;
        state.prices = {total_price:total,paid_price:paid,refund_price: refund, no_paid: not_paid};
        },
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_ORDER_MODEL: (state,model) => (state.order_payments = model),
    SET_MODEL: (state,model) => {
        state.model.id = model.id;
        state.model.patient_id = model.patient_id;
        state.model.room_id = model.room_id;
        state.model.day = model.day;
        state.model.notes = model.notes;
        state.model.condition_id = model.condition_id;
        state.model.partner_doctor_id = model.partner_doctor_id;
        state.model.yes_doctor = model.yes_doctor;
        state.model.doctor_id = model.doctor_id;
        state.model.from_hour = model.from_hour;
        state.model.duration = model.duration;
        state.model.created_at = model.created_at;
        state.model.updated_at = model.updated_at;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
        state.sort = JSON.parse( JSON.stringify( sort ));
        state.pagination = JSON.parse( JSON.stringify( pagination ));
    },
    UPDATE_LANG_COLUMN: (state) => {
            state.columns.id.title = "№";
            state.columns.patient_id.title = i18n.t('message.patient');
            state.columns.total_price.title = i18n.t('message.total_amount');
            state.columns.paid_price.title = i18n.t('message.paid');
            state.columns.notes.title = i18n.t('message.notes');
            state.columns.condition_id.title = i18n.t('message.condition');
            state.columns.doctor_id.title = i18n.t('message.doctor_0');
            state.columns.created_at.title = i18n.t('message.date');
            state.columns.settings.title = i18n.t('message.settings');
    }
};
