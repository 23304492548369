<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Валюта'"
    filterable
    clearable
    :size="size"
    class="d-block"
    @change="changed()"
  >
    <el-option 
      v-for="currency in currencies"
      :key="currency.id"
      :label="currency.name"
      :value="currency.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [
    inventory
  ],
  mounted() {
    if (this.currencies && this.currencies.length === 0) {
      this.getInventory();
    }
  },
  props: [
    'currency_id'
  ],
  watch: {
    currency_id: {
      handler: function () {
        this.dispatch(this.currency_id);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies/inventory'
    })
  },
  methods: {
    ...mapActions({
      getInventory: 'currencies/inventory'
    }),
    changed() {
      this.$emit('changed');
    }
  }
}
</script>