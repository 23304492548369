import { actions } from './treatment_product_list/actions'
import { getters } from './treatment_product_list/getters'
import { state } from './treatment_product_list/state'
import { mutations } from './treatment_product_list/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
