export const model = {
  date: '',
  supplier_id: '',
  supplier_contract_id: '',
  delivery_deadline: '',
  status_id: '',
  description: '',
  items: [],
  old_items: [],
  new_items: []
};