import {dashboardInfo} from '../../../api/dashboards';

export const actions = {
    dashboardInfo({commit}) {
        return new Promise((resolve,reject) => {
            dashboardInfo().then(res => {
                commit("SET_DASHBOARDINFO", res.data.data);
                resolve(res.data)
            }).catch(err => {
                console.log(err);
                reject(err.response.data)
            })
        })
    },

}
