export const model = {
    id: null,
    name: '', 
    corps_id: '',
    floor_id: '',
    class_id: '',   
    hospitalRoom_id: "",
    created_at: '',
    updated_at: ''
};
