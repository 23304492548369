import Layout from '@/layouts'

const patient = {
  path: '/patients',
  component: Layout,
  name: 'patients',
  redirect: '/patients/index',
  children: [
    {
      path: '/patients/index',
      component: () => import('@/views/patient/index'),
      name: 'patients.index',
      meta: { title: 'Типы расхода'}
    },
  ]
}

export default patient