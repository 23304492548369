import { actions } from './auth/actions'
import { getters } from './auth/getters'
import { state } from './auth/state'
import { mutations } from './auth/mutations'

export default {
    getters,
    state,
    mutations,
    actions
}
