export const rules = {
  date: [
    { required: true, message: 'Пожалуйста укажите дату', trigger: 'blur' },
  ],
  status_id: [
    { required: true, message: 'Пожалуйста укажите статус', trigger: 'blur' },
  ],
  supplier_id: [
    { required: true, message: 'Пожалуйста укажите поставщика', trigger: 'blur' },
  ],
};