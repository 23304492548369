import { filter } from './properties/filter'
import {model} from "./properties/model";
import {rules} from "./properties/rules";
import { columns } from './properties/columns'

export const state = {
    list: [],
    inventory: [],
    model: JSON.parse( JSON.stringify( model )),
    filter: filter,
    columns: columns,
    rules: rules
};
