import Layout from '@/layouts'

const writeOffs = {
  path: '/write-offs',
  component: Layout,
  name: 'writeOffs',
  redirect: '/write-offs/index',
  children: [{
    path: '/write-offs/index',
    component: () => import('@/views/writeOffs/index'),
    name: 'writeOffs.index',
    meta: {
      title: 'Списание'
    }
  }, ]
}

export default writeOffs;