import { actions } from './doctorSigns/actions'
import { getters } from './doctorSigns/getters'
import { state } from './doctorSigns/state'
import { mutations } from './doctorSigns/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
