import Layout from '@/layouts'

const debtsRouter = {
    path: '/debtors',
    component: Layout,
    name: 'debts',
    redirect: '/debtors/index',
    children: [
        {
          path: '/debtors/index',
          component: () => import('@/views/debtors/index'),
          name: 'debtors.index',
          meta: { title: 'debtors'}
        },
    ]
}

export default debtsRouter