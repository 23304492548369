export const model = {
    id: null,
    name: '',
    rate: '',
    reverse: false,
    reversed_rate: 1,
    symbol: '',
    code: '',
    active: false
};
