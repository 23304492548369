import { i18n } from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    number: {
        show: true,
        title:  i18n.t('message.contracts_n'),
        sortable: true,
        column: 'number'
    },
    parent_id: {
        show: false,
        title: i18n.t('message.contract_parent'),
        sortable: true,
        column: 'parent_id'
    },
    begin_date: {
        show: true,
        title: i18n.t('message.date_aggrement'),
        sortable: true,
        column: 'begin_date'
    },
    end_date: {
        show: true,
        title: i18n.t('message.term_aggrement'),
        sortable: true,
        column: 'end_date'
    },
    sum: {
        show: true,
        title: i18n.t('message.price_aggrement'),
        sortable: true,
        column: 'sum'
    },
    remainder: {
        show: true,
        title: i18n.t('message.remainder'),
        sortable: true,
        column: 'remainder'
    },
    orders_amount:{
        show: true,
        title: i18n.t('message.order_amount'),
        sortable: true,
        column: 'orders_amount'
    },
    organization_id: {
        show: true,
        title: i18n.t('message.organization'),
        sortable: true,
        column: 'organization_id'
    },
    status_id: {
        show: false,
        title: i18n.t('message.status'),
        sortable: true,
        column: 'status_id'
    },
    comment: {
        show: false,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    created_at: {
        show: false,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    print: {
        show: true,
        title: i18n.t('message.printing'),
        sortable: true,
        column: 'print'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
