export const model = {
    id: null,
    patientBalance: {
        total_amount: '',
        paid_up: '',
        not_paid: ''
    }, 
    corps_id: '',   
    created_at: '',
    updated_at: ''
};
