import Layout from '@/layouts'

const hour = {
  path: '/cashierStationary',
  component: Layout,
  name: 'cashierStationary',
  redirect: '/cashierStationary/index',
  children: [{
    path: '/cashierStationary/index',
    component: () => import('@/views/cashierStationary/index'),
    name: 'cashierStationary.index',
    meta: {
      title: 'Времени'
    }
  }, ]
}

export default hour