export const statues = [
    {
      code: "active",
      label: "Активний"
    },
    {
      code: "deactive",
      label: "Не астивний"
    }
  ]
