export const filter = {
  id: '',
  name: '',
  full_name: '',
  code: '',
  phone: '',
  fax: '',
  email: '',
  description: '',
  actual_address: '',
  type: '',
  legal_address: '',
  inn: '',
  mfo: '',
  okonx: '',
  oked: '',
  rkp_nds: '',
};