<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_patient") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="waiting"
                :disabled="loading ? true : false"
                @click="submit(true)"
                type="success"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('formPatient')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="grid-content">
      <div class="add_patient">
        <el-form ref="formPatient" :model="form" :rules="rules" class="twoform">
          <el-row :gutter="24">
            <el-col :sm="12">
              <el-form-item :label="columns.first_name.title" prop="first_name">
                <el-input
                  v-model="form.first_name"
                  :placeholder="columns.first_name.title"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12">
              <el-form-item :label="columns.surname.title" prop="surname">
                <el-input
                  v-model="form.surname"
                  :placeholder="columns.surname.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="columns.patronymic.title" prop="patronymic">
                <el-input
                  v-model="form.patronymic"
                  :placeholder="columns.patronymic.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="columns.phone.title">
                <el-input
                  v-model="form.phone"
                  :placeholder="columns.phone.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="columns.born_date.title">
                <el-date-picker
                  type="date"
                  v-model="form.born_date"
                  :placeholder="columns.born_date.title"
                  style="width: 100%"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <!-- <el-col :sm="12">
              <el-form-item :label="columns.drive_license.title">
                <crm-input
                  :placeholder="'AA1111010'"
                  :maskFormat="'passport'"
                  :inputValue="form.passport_number"
                  v-model="form.passport_number"
                  :size="'large'"
                ></crm-input>
              </el-form-item>
            </el-col> -->

            <!-- <el-col :sm="12">
              <el-form-item :label="columns.drive_license.title">
                <crm-input
                  :placeholder="'AA1111010'"
                  :maskFormat="'passport'"
                  :inputValue="form.drive_license"
                  v-model="form.drive_license"
                  :size="'large'"
                ></crm-input>
              </el-form-item>
            </el-col> -->

            <!-- <el-col :sm="12">
              <el-form-item :label="columns.social_id.title" prop="social_id">
                <el-select
                  v-model="form.social_id"
                  :placeholder="columns.social_id.title"
                >
                  <el-option
                    v-for="item in socials"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col> -->

            <el-col :sm="12">
              <el-form-item :label="columns.code.title" prop="code">
                <el-input
                  v-model="form.code"
                  :placeholder="columns.code.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <div class="pol_radio">
                <el-form-item :label="columns.gender.title" prop="gender">
                  <el-radio-group v-model="form.gender">
                    <el-radio label="man" border size="medium">{{
                      $t("message.male")
                    }}</el-radio>
                    <el-radio label="woman" border size="medium">{{
                      $t("message.female")
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="24">
              <div class="grid-content mb20">
                <el-tabs
                  v-model="activetab"
                  class="ifoData mb20"
                  type="border-card"
                >
                  <el-tab-pane
                    :label="$t('message.address_register')"
                    name="first"
                  >
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item
                          :label="$t('message.country')"
                          prop="state_id"
                        >
                          <states
                            v-model="form.state_id"
                            :id="model.state_id"
                            :state_id="form.state_id"
                          ></states>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.region')">
                          <regions
                            v-model="form.region_id"
                            :id="model.region_id"
                            :region_id="form.region_id"
                            :state_id="form.state_id"
                            @state_id="setState"
                          ></regions>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.city')">
                          <cities
                            v-model="form.city_id"
                            :id="model.city_id"
                            :region_id="form.region_id"
                            :state_id="form.state_id"
                            :city_id="form.city_id"
                            @region_id="setRegion"
                          ></cities>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.district')">
                          <districts
                            v-model="form.district_id"
                            :id="model.district_id"
                            :city_id="form.city_id"
                            :state_id="form.state_id"
                            :district_id="form.district_id"
                            @city_id="setCity"
                          ></districts>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.street')">
                          <el-input
                            v-model="form.street"
                            :placeholder="$t('message.street')"
                          ></el-input>
                        </el-form-item>
                      </el-col>

                      <!-- <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.district')">
                          <el-input
                            v-model="form.district"
                            :placeholder="$t('message.district')"
                          ></el-input>
                        </el-form-item>
                      </el-col> -->

                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.house')">
                          <el-input
                            v-model="form.home"
                            :placeholder="$t('message.house')"
                          ></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.flat')">
                          <el-input
                            v-model="form.flat"
                            :placeholder="$t('message.flat')"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-tab-pane>
                  <!-- <el-tab-pane
                    :label="$t('message.organization')"
                    name="second"
                  >
                    <el-row :gutter="20">
                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="$t('message.organization')"
                            prop="document_type_id"
                          >
                            <el-select
                              v-model="form.organization_id"
                              :placeholder="$t('message.organization')"
                              filterable
                              clearable
                            >
                              <el-option
                                v-for="item in list_organization"
                                :key="item.id + item.company_name"
                                :label="item.company_name"
                                :value="item.id"
                              >
                                <span style="float: left">{{
                                  item.company_name
                                }}</span>
                                <span
                                  style="
                                    float: right;
                                    color: #8492a6;
                                    font-size: 13px;
                                  "
                                  >{{ $t("message.inn") }} {{ item.inn }}</span
                                >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </el-col>
                    </el-row>
                  </el-tab-pane>

                  <el-tab-pane :label="$t('message.documents')" name="thero">
                    <el-row :gutter="20">
                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.document_type_id.title"
                            prop="document_type_id"
                          >
                            <el-select
                              v-model="form.document_type_id"
                              :placeholder="columns.document_type_id.title"
                              filterable
                              clearable
                            >
                              <el-option
                                v-for="item in document_types"
                                :key="item.id + item.name"
                                :label="item.name"
                                :value="item.id"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </el-col>

                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.series_and_number.title"
                            prop="series_and_number"
                          >
                            <el-input
                              v-model="form.series_and_number"
                              :placeholder="columns.series_and_number.title"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </el-col>

                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.date_of_issue.title"
                            prop="date_of_issue"
                          >
                            <el-date-picker
                              type="date"
                              v-model="form.date_of_issue"
                              value-format="yyyy-MM-dd"
                              value="yyyy-MM-dd"
                              :placeholder="columns.date_of_issue.title"
                              style="width: 100%"
                            ></el-date-picker>
                          </el-form-item>
                        </div>
                      </el-col>

                      <el-col :sm="12">
                        <div class="grid-content">
                          <el-form-item
                            :label="columns.authority.title"
                            prop="authority"
                          >
                            <el-input
                              v-model="form.authority"
                              :placeholder="columns.authority.title"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                    </el-row>
                  </el-tab-pane> -->
                </el-tabs>
              </div>
            </el-col>

            <el-col :span="24" class="mt-3">
              <el-checkbox-group v-model="form.tags" size="mini">
                <el-checkbox-button
                  v-for="tag in tags"
                  :key="tag.id"
                  :label="tag.id"
                  >{{ tag.name }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import cities from "@/components/selects/city";
import regions from "@/components/selects/region";
import districts from "@/components/selects/district";
import states from "@/components/selects/state";
import _ from "lodash";
export default {
  props: ["open", "patient", "drawer"],
  components: {
    regions,
    cities,
    states,
    districts,
  },
  data() {
    return {
      waiting: false,
      form: {},
      activetab: "first",
      loading: false,
    };
  },
  watch: {
    open: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.loadModel();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    if (this.socials && this.socials.length === 0) await this.loadSocials();
    if (this.organizations && this.organizations.length === 0)
      await this.loadOrganizations();
    if (this.tags && this.tags.length === 0) await this.loadTags();
    if (this.document_types && this.document_types.length === 0)
      await this.loadDocumentTypes();
  },
  computed: {
    ...mapGetters({
      rules: "patients/rules",
      model: "patients/model",
      columns: "patients/columns",
      socials: "socials/list",
      organizations: "organizations/list",
      document_types: "documentTypes/list",
      tags: "tags/list",
    }),
  },
  methods: {
    ...mapActions({
      save: "patients/update",
      loadSocials: "socials/index",
      loadTags: "tags/index",
      loadOrganizations: "organizations/index",
      loadDocumentTypes: "documentTypes/index",
      show: "patients/show",
    }),
    setRegion(id) {
      this.form.region_id = id;
    },
    setState(id) {
      this.form.state_id = id;
    },
    setCity(id) {
      this.form.city_id = id;
    },
    loadModel() {
      this.loading = true;
      this.show(this.patient.id)
        .then((res) => {
          this.form = JSON.parse(JSON.stringify(this.model));
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    submit(close = true) {
      // this.$refs["formPatient"].validate((valid) => {
      // if (valid) {
      this.waiting = true;
      this.save(this.form)
        .then((res) => {
          this.waiting = false;
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.form = {};
          this.form.tags = [];
          this.resetForm("formPatient");
          // this.$emit("c-close", {
          //   reload: true,
          //   drawer: this.drawer,
          //   patient: res.data.patient,
          // });
        })
        .catch((err) => {
          this.waiting = false;
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
      // }
      // });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: this.drawer });
    },
  },
};
</script>
