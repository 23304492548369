import {
    i18n
} from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    service_name: {
        show: true,
        title: i18n.t('message.service_name'),
        sortable: true,
        column: 'service_name'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};