import { i18n } from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: '№',
    sortable: true,
    column: 'id'
  },
  name: {
    show: true,
    title:  i18n.t('message.name'),
    sortable: true,
    column: 'name'
  },
  parent: {
    show: true,
    title: i18n.t('message.parent_category'),
    sortable: true,
    column: 'parent_id'
  },
  created_at: {
    show: true,
    title: i18n.t('message.date'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
    show: true,
    title: i18n.t('message.update_date'),
    sortable: true,
    column: 'updated_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }
};