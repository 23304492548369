export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    ],
    status: [
        { required: true, message: 'Пожалуйста, введите статус ', trigger: 'blur' },
    ],
    color: [
        { required: true, message: 'Пожалуйста, выберите цвет ', trigger: 'blur' },
    ],
};
