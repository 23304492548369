import Layout from '@/layouts'

const printedForm = {
  path: '/printedForm',
  component: Layout,
  name: 'printForm',
  redirect: '/printedForm/index',
  children: [
    {
      path: '/printedForm/index',
      component: () => import('@/views/printedForm/index'),
      name: 'printedForm',
      meta: { title: 'aaaa'}
    },
    {
      path: '/printCenter/index',
      component: () => import('@/views/printCenter/index'),
      name: 'printCenter',
      meta: { title: 'printCenter'}
    },
    {
      path: '/printCenter/printCenter',
      component: () => import('@/views/printCenter/printCenter'),
      name: 'printCenterList',
      meta: { title: 'printCenterList'}
    }
  ]
}

export default printedForm