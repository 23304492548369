import { actions } from './hour/actions'
import { getters } from './hour/getters'
import { state } from './hour/state'
import { mutations } from './hour/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
