export const filter = {
    id: "",
    patient_id: "",
    day: "",
    notes: "",
    condition_id: "",
    doctor_id:"",
    partner_doctor_id: "",
    from_hour: "",
    duration: "",
    search: "",
    created_at: ""
};
