import request from './../utils/request'

export function index(params) {
    return request({
        url: '/autoWriteOffs',
        method: 'get',
        params
    })
}

export function show(id) {
    return request({
        url: `/autoWriteOffs/${id}`,
        method: 'get'
    })
}


export function store(data) {
    return request({
        url: '/autoWriteOffs',
        method: 'post',
        data
    })
}



export function update(datas) {
    let data = datas.data
    let id = datas.id
    return request({
        url: `/autoWriteOffs/${id}`,
        method: 'put',
        data
    })
}

export function destroy(id) {
    return request({
        url: `/autoWriteOffs/${id}`,
        method: 'delete',
    })
}
