import { actions } from './schedule/actions'
import { getters } from './schedule/getters'
import { state } from './schedule/state'
import { mutations } from './schedule/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
