import request from '../utils/request'


export function index(params) {
  return request({
    url: '/InsuranceCompanyReport',
    method: 'get',
    params
  })
}



export function show(params) {
  return request({
    url: `/InsuranceCompanyReport/${params.id}`,
    method: 'get',
    params
  })
}


