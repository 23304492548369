import { actions } from './print_auditable/actions'
import { getters } from './print_auditable/getters'
import { state } from './print_auditable/state'
import { mutations } from './print_auditable/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
