import { actions } from './injuryType/actions'
import { getters } from './injuryType/getters'
import { state } from './injuryType/state'
import { mutations } from './injuryType/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
