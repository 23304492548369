export const rules = {
    status: [
        { required: true, message: 'Пожалуйста, выберите пациент ', trigger: 'change' },
    ],
    // room_id: [
    //     { required: true, message: 'Пожалуйста, выберите комната ', trigger: 'change' },
    // ],
    // condition_id: [
    //     { required: true, message: 'Пожалуйста, выберите состояние ', trigger: 'change' },
    // ],
    // day: [
    //     { required: true, message: 'Пожалуйста, выберите день ', trigger: 'change' },
    // ],
};
