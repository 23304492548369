import { index, show, getRep } from "./../../../api/dotorsReport";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.data.data);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.pagination.total });
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getRep({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getRep(params).then(res => {

                resolve(res);
            })
                .catch(err => {
                    reject(err);
                });
        });
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    // commit('SET_MODEL', res.data.data.data.doctors);
                    // commit('SET_REPORTS', res.data.data.reports);
                    commit('SET_BONUS', res.data.data.bonus);
                    commit('SET_SECONDARY_BONUS', res.data.data.secondary_bonus);
                    commit("UPDATE_SHOW_PAGINATION", { key: 'total', value: res.data.data.bonus.data.pagination.total });
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    updateShowPagination({ commit }, pagination) {
        commit('UPDATE_SHOW_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
