import { actions } from './condition/actions'
import { getters } from './condition/getters'
import { state } from './condition/state'
import { mutations } from './condition/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
