import { actions } from './departmentUser/actions'
import { getters } from './departmentUser/getters'
import { state } from './departmentUser/state'
import { mutations } from './departmentUser/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
