export const getters = {
    list: (state) => state.list,
    inventory: (state) => state.inventory,
    columns: (state) => state.columns,
    model: (state) => state.model,
    filter: (state) => state.filter,
    pagination: (state) => state.pagination,
    sort: (state) => state.sort,
    rules: (state) => state.rules,
    search_list: (state) => state.search_list,
};
