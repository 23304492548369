import { actions } from './outsource/actions'
import { getters } from './outsource/getters'
import { state } from './outsource/state'
import { mutations } from './outsource/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
