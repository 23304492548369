import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    company_name: {
        show: true,
        title: i18n.t('message.company_name'),
        sortable: true,
        column: 'company_name'
    },
    phone: {
        show: true,
        title: i18n.t('message.phone'),
        sortable: false,
        column: 'phone'
    },
    code: {
        show: true,
        title: i18n.t('message.code'),
        sortable: false,
        column: 'code'
    },
    fax: {
        show: true,
        title: i18n.t('message.fax'),
        sortable: false,
        column: 'fax'
    },
    email: {
        show: true,
        title: i18n.t('message.email'),
        sortable: false,
        column: 'email'
    },
    address: {
        show: true,
        title: i18n.t('message.address'),
        sortable: false,
        column: 'address'
    },
    inn: {
        show: true,
        title: i18n.t('message.inn'),
        sortable: true,
        column: 'inn'
    },
    mfo: {
        show: false,
        title: i18n.t('message.mfo'),
        sortable: false,
        column: 'mfo'
    },
    okonx: {
        show: false,
        title: i18n.t('message.okonx'),
        sortable: false,
        column: 'okonx'
    },
    oked: {
        show: false,
        title: i18n.t('message.oked'),
        sortable: false,
        column: 'oked'
    },
    rkp_nds: {
        show: false,
        title: i18n.t('message.rkp_nds'),
        sortable: false,
        column: 'rkp_nds'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: true,
        column: 'settings'
    }
};
