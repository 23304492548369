import Layout from '@/layouts'

const laboratoryCabinet = {
    path: '/laboratory-cabinet',
    component: Layout,
    name: 'laboratoryCabinet',
    redirect: '/laboratory-cabinet/patients',
    children: [
      {
        path: '/laboratory-cabinet/patients',
        component: () => import('@/views/laboratoryCabinet/patients'),
        name: 'laboratory-cabinet.patients',
        meta: { title: 'Мой Кабинет'}
      },
      {
        path: '/laboratory-cabinet/informal-patients',
        component: () => import('@/views/laboratoryCabinet/informal-patients'),
        name: 'laboratory-cabinet.informal-patients',
        meta: { title: 'Мой Кабинет'}
      },
      {
        path: '/laboratory-cabinet/AllPatients',
        component: () => import('@/views/laboratoryCabinet/AllPatients'),
        name: 'laboratory-cabinet.AllPatients',
        meta: { title: 'Пациенты'}
      },
      // {
      //   path: '/laboratory-cabinet/patientMedicine',
      //   component: () => import('@/views/patientMedicine/index'),
      //   name: 'laboratory-cabinet.patientMedicine',
      //   meta: { title: 'Мой Кабинет'}
      // },
      // {
      //   path: '/laboratory-cabinet/patientMedicineShow',
      //   component: () => import('@/views/patientMedicineShow/index'),
      //   name: 'laboratory-cabinet.patientMedicineShow',
      //   meta: { title: 'Мой Кабинет'}
      // },
      // {
      //   path: '/laboratory-cabinet/conclusions/:id',
      //   component: () => import('@/views/laboratoryCabinet/components/docCabPatientHistory'),
      //   name: 'patientConclusions',
      //   meta: { title: 'Пациенты'}
      // },
      // {
      //   path: '/laboratory-cabinet/components/addDocument',
      //   component: () => import('@/views/laboratoryCabinet/components/addDocument'),
      //   name: 'addDocument',
      //   meta: { title: 'добавить документ'}
      // },
    ]
  }

export default laboratoryCabinet