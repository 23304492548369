import {index, inventory} from '@/api/debts';
import {show} from "@/api/departments";

export const actions = {
    index({commit}, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then((res) => {
                    console.log(res, 'ress action')
                    commit("SET_LIST", res.data.data.debts.data);
                    // commit("UPDATE_PAGINATION", {
                    //     key: "total",
                    //     value: res.data.data.debts.pagination.total,
                    // });
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log(err, 'err action')
                    reject(err.response);
                });
        });
    },
    inventory({commit}, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params)
                .then((res) => {
                    commit("SET_INVENTORY", res.data.data.debts.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response);
                });
        });
    },
    updateSort({
                   commit
               }, sort) {
        commit("SET_SORT", sort);
    },

    updateFilter({
                     commit
                 }, filter) {
        commit("SET_FILTER", JSON.parse(JSON.stringify(filter)));
    },

    updateColumn({
                     commit
                 }, obj) {
        commit("UPDATE_COLUMN", obj);
    },
    updatePagination({
                         commit
                     }, pagination) {
        commit("UPDATE_PAGINATION", pagination);
    },

    refreshData({
                    commit
                }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve();
        });
    },
    empty({
              commit
          }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve();
        });
    },

    emptySearch({
                    commit
                }) {
        commit("EMPTY_SEARCH");
    },
};