export const getters = {
  list: state => state.list,
  model: state => state.model,
  total_amount: state => state.total_amount,
  patient_services: state => state.patient_services,
  relation_model: state => state.relation_model,
  modell: state => state.modell,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort,
  rules: state => state.rules,
  inventory: state => state.inventory
};