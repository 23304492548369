import { actions } from './sickNumbers/actions'
import { getters } from './sickNumbers/getters'
import { state } from './sickNumbers/state'
import { mutations } from './sickNumbers/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
