import { actions } from './work_time/actions'
import { getters } from './work_time/getters'
import { state } from './work_time/state'
import { mutations } from './work_time/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
