import request from '../utils/request'


export function index(params) {
  return request({
    url: '/branches',
    method: 'get',
      params
  })
}

export function inventorywithWarehouse(params) {
  return request({
    url: '/branches/inventorywithWarehouse',
    method: 'get',
    params
  })
}
export function inventory(params) {
  return request({
    url: '/branches/inventory',
    method: 'get',
    params
  })
}

export function getLastId(params) {
  return request({
    url: '/branches/getLastId',
    method: 'get',
    params
  })
}
export function getOwnerBranchDepartments(params) {
  return request({
    url: '/branches/getOwnerBranchDepartments',
    method: 'get',
    params
  })
}
export function show(id) {
    return request({
      url: `/branches/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/branches',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/branches/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/branches/${id}`,
      method: 'delete',
    })
  }
