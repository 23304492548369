export const model = {
    id: null,
    transactionable_id: "",
    payment_type_id: "",
    price: 0,
    currency_id: "",
    rate: 1,
    date: "",
    comment: "", 
    debit: -1,  
    created_at: '',
    updated_at: ''
};
