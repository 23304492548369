import Layout from '@/layouts'

const patientAgeReport = {
    path: '/patientAgeReport',
    component: Layout,
    name: 'patientAgeReport',
    redirect: '/patientAgeReport/index',
    children: [{
      path: '/patientAgeReport/index',
      component: () => import('@/views/patientAgeReport/index'),
      name: 'patientAgeReport.index',
      meta: {
        title: 'График'
      }
    }, ]
  }
  

export default patientAgeReport