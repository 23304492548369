import { actions } from './patientIndicatorIndex/actions'
import { getters } from './patientIndicatorIndex/getters'
import { state } from './patientIndicatorIndex/state'
import { mutations } from './patientIndicatorIndex/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
