import { i18n } from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: "id"
      },
      branch: {
        show: true,
        title: i18n.t('message.branch'),
        sortable: true,
        column: "branch"
      },
      tenant: {
        show: true,
        title: i18n.t('message.drug'),
        sortable: true,
        column: "tenant"
      },
      dogNamber: {
        show: true,
        title: i18n.t('message.quantity'),
        sortable: true,
        column: "dogNamber"
      }
};
