import Layout from '@/layouts'

const schedule = {
  path: '/nurseOfficePhy',
  component: Layout,
  name: 'nurseOfficePhy',
  redirect: '/nurseOfficePhy/index',
  children: [{
    path: '/nurseOfficePhy/index',
    component: () => import('@/views/nurseOfficePhy/index'),
    name: 'nurseOfficePhy.index',
    meta: {
      title: 'Кабинет медсестра '
    }
  }, ]
}

export default schedule