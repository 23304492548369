import { i18n } from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    partner_clinic: {
        show: true,
        title: i18n.t('message.partner_clinic'),
        sortable: false,
        column: 'partner_clinic'
    },
    courier: {
        show: true,
        title: i18n.t('message.courier'),
        sortable: false,
        column: 'courier'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
 
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};