import { actions } from './process/actions';
import { getters } from './process/getters';
import { state } from './process/state';
import { mutations } from './process/mutations';

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};