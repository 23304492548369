import { actions } from './patientServiceReport/actions'
import { getters } from './patientServiceReport/getters'
import { state } from './patientServiceReport/state'
import { mutations } from './patientServiceReport/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}