import { actions } from './report_limit/actions'
import { getters } from './report_limit/getters'
import { state } from './report_limit/state'
import { mutations } from './report_limit/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}