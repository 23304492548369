import Layout from '@/layouts'

const schedule = {
  path: '/nurseOffice',
  component: Layout,
  name: 'nurseOffice',
  redirect: '/nurseOffice/index',
  children: [{
    path: '/nurseOffice/index',
    component: () => import('@/views/nurseOffice/index'),
    name: 'nurseOffice.index',
    meta: {
      title: 'Кабинет медсестра '
    }
  }, ]
}

export default schedule