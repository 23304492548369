import request from './../utils/request';

export function index(params) {
  return request({
    url: '/requirements',
    method: 'GET',
    params
  });
}

export function show(id) {
  return request({
    url: '/requirements/' + id,
    method: 'GET'
  });
}

export function store(data) {
  return request({
    url: '/requirements',
    method: 'POST',
    data
  });
}

export function set_status(data) {
  return request({
    url: `/requirements/set_status/${data.status}/${data.id}`,
    method: 'POST'
  })
}

export function update(data) {
  return request({
    url: '/requirements/' + data.id,
    method: 'PUT',
    data
  });
}

export function destroy(id) {
  return request({
    url: '/requirements/' + id,
    method: 'DELETE'
  });
}

export function destroyItem(id) {
  return request({
    url: `/requirements/item/${id}`,
    method: 'DELETE'
  });
}

export function print(id) {
  return request({
    url: `/requirements/print/${id}`,
    method: 'GET'
  });
}