import Layout from '@/layouts'

const cashierRouter = {
  path: '/cashier',
  component: Layout,
  name: 'cashier',
  redirect: 'cashier',
  children: [
    {
      path: 'cashier',
      component: () => import('@/views/cashier/index'),
      name: 'cashier.index',
      meta: { title: 'cashier'}
    },
    {
      path: 'cashier/history',
      component: () => import('@/views/cashier/history'),
      name: 'history',
      meta: { title: 'history'}
    },
  ]
}

export default cashierRouter