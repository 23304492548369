import { i18n } from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: '№',
    sortable: true,
    column: 'id'
  },
  name: {
    show: true,
    title: i18n.t('message.name'),
    sortable: true,
    column: 'name'
  },
  warehouse_id: {
    show: true,
    title: i18n.t('message.warehouse'),
    sortable: true,
    column: 'warehouse_id'
  },
  user: {
    show: true,
    title: i18n.t('message.recipient'),
    sortable: true,
    column: 'user'
  },
  code: {
    show: false,
    title: i18n.t('message.code'),
    sortable: true,
    column: 'code'
  },
  sku: {
    show: false,
    title: i18n.t('message.sku'),
    sortable: true,
    column: 'sku'
  },
  category: {
    show: true,
    title: i18n.t('message.group'),
    sortable: true,
    column: 'category_id'
  },
  measurement: {
    show: true,
    title:  i18n.t('message.measurement'),
    sortable: true,
    column: 'measurement_id'
  },
  vat: {
    show: false,
    title: i18n.t('message.nds'),
    sortable: true,
    column: 'vat'
  },
  available: {
    show: true,
    title:   i18n.t('message.remaind'),
    sortable: true,
    column: 'available'
  },
  used: {
    show: true,
    title:   i18n.t('message.used'),
    sortable: true,
    column: 'used'
  },
  used_medicine: {
    show: true,
    title:   i18n.t('message.used_medicine'),
    sortable: true,
    column: 'used_medicine'
  },
  received: {
    show: true,
    title: i18n.t('message.qty_come'),
    sortable: true,
    column: 'received'
  },
  created_at: {
    show: true,
    title: i18n.t('message.date'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
      show: false,
      title: i18n.t('message.update_date'),
      sortable: true,
      column: 'updated_at'
  },
  settings: {
      show: true,
      title: i18n.t('message.settings'),
      sortable: false,
      column: 'settings'
  }
};