import Layout from '@/layouts'

const city = {
  path: '/requirements',
  component: Layout,
  name: 'requirement',
  redirect: '/requirements/index',
  children: [{
    path: '/requirements/index',
    component: () => import('@/views/requirements/index'),
    name: 'requirement.index',
    meta: {
      title: 'Требование'
    }
  }, ]
}

export default city