import {sort} from "../properties/sort";
import {filter} from "./properties/filter";
import {pagination} from "../properties/pagination";
import {model} from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_INVENTORY: (state, inventory) => (state.inventory = inventory),
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_MODEL: (state,model) => {
        state.model = model;
    },
    SET_LIST: (state, list) => {
        state.list = list;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
        state.sort = JSON.parse( JSON.stringify( sort ));
        state.pagination = JSON.parse( JSON.stringify( pagination ));
    },
    UPDATE_LANG_COLUMN: (state) => {
    
            state.columns.id.title = "№";
            state.columns.warehouse_id.title =   i18n.t('message.warehouse');
            state.columns.available.title =   i18n.t('message.available');
            state.columns.day_in_warehouse.title = i18n.t('message.day_in_warehouse'); 
            state.columns.rate.title = i18n.t('message.rate');
            state.columns.price.title = i18n.t('message.new_price');
            state.columns.price_all.title = i18n.t('message.price2');
            state.columns.new_price.title = i18n.t('message.new_price');
            state.columns.new_price_all.title =  i18n.t('message.sale_price');
            state.columns.percent.title = i18n.t('message.percent');
            state.columns.manufactured_date.title = i18n.t('message.manufactured_date');
            state.columns.settings.title = i18n.t('message.settings');

        
    }
};
