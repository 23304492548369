import { info } from '../../../api/homes';

export const actions = {

    info({commit}){
        return new Promise((resolve, reject) => {
            info().then(res => {
                resolve(res.data.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

}