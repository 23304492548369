import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },

    bunk_id: {
        show: true,
        title: i18n.t('message.corps'),
        sortable: true,
        column: 'bunk_id'
    },

    resource: {
        show: true,
        title: i18n.t('message.corps'),
        sortable: true,
        column: 'resource'
    },
    start: {
        show: true,
        title: i18n.t('message.corps'),
        sortable: true,
        column: 'start'
    },
    end: {
        show: true,
        title: i18n.t('message.class'),
        sortable: true,
        column: 'end'
    },
    text: {
        show: true,
        title: i18n.t('message.floor'),
        sortable: true,
        column: 'text'
    },
    color: {
        show: true,
        title: i18n.t('message.color'),
        sortable: true,
        column: 'color'
    },
};
