export const model = {
    id: null,
    patient_id: "",
    total_price: "",
    paid_price: "",
    room_id: "",
    day: "",
    notes: "",
    condition_id: "",
    partner_doctor_id: "",
    yes_doctor: "",
    doctor_id: "",
    from_hour: "",
    duration: "",  
    services: "", 
    created_at: '',
    updated_at: ''
};
