import { actions } from './patientAge/actions'
import { getters } from './patientAge/getters'
import { state } from './patientAge/state'
import { mutations } from './patientAge/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
