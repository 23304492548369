export const model = {
    id: null,
    first_name: "",
    surname: "",
    patronymic: "",
    phone: "",
    country_code: "",
    born_date: "",
    social_id: "",
    document_type_id: "",
    series_and_number: "",
    date_of_issue: "",
    authority: "",
    state_id: "",
    organization_id: "",
    passport_number: null,
    drive_license: null,
    city_id: "",
    region: "",
    code: "",
    region_id: "",
    street: "",
    area: "",
    home: "",
    flat: "",
    district_id: "",
    inn: "",
    onlis: "",
    gender: "man",
    tags: [],
    search: "",
    created_at: "",
    updated_at: "",
};
