import { patientMedicineShow,patientShow,patientListMedicine, patientMedicineShowOrder,patientMedicineUpdate } from '../../../api/patientMedicine';

export const actions = {

   

    

    patientMedicine({ commit }, data) {
        return new Promise((resolve, reject) => {
            patientMedicine(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    patientMedicineShow({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            patientMedicineShow(params).then(res => {
                commit("SET_PATIENT_MEDICINE", res.data.data.patient_medicine.data);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.patient_medicine.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    patientMedicineUpdate({commit},data) {
        return new Promise((resolve,reject) => {
            patientMedicineUpdate(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    patientListMedicine({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            patientListMedicine(params).then(res => {
                commit("SET_PATIENT_LIST", res.data.data.orders.data);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.orders.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    patientShow({ commit }, id) {
        return new Promise((resolve, reject) => {
            patientShow(id).then(res => {
                commit("SET_MODEL", res.data.data.patient_medicine);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    patientMedicineShowOrder({ commit }, id) {
        return new Promise((resolve, reject) => {
            patientMedicineShowOrder(id).then(res => {
                commit("SET_MODEL_ORDER", res.data.data.patient_medicine);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    chart({ commit }, type) {
        return new Promise((resolve, reject) => {
            chart({ type: type }).then(res => {
                resolve(res.data.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

}