import { index, show, scoreReport, excelReport, store, update, destroy, remove, refund, reports, chart } from '../../../api/transaction';

export const actions = {

    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.transactions.data);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.transactions.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    excelReport({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            excelReport(params).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    reports({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            reports(params).then(res => {
                commit("SET_REPORTS", res.data.data.transactions.data);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.transactions.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    scoreReport({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            scoreReport(params).then(res => {

                commit("SET_SCORE_REPORTS", res.data);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    relationList({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_RELATION_LIST", res.data.data.transactions);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    chart({ commit }, type) {
        return new Promise((resolve, reject) => {
            chart({ type: type }).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },


    show({ commit }, data) {
        return new Promise((resolve, reject) => {
            show(data).then(res => {
                commit("SET_MODEL", res.data.data.transaction);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    refund({ commit }, data) {
        return new Promise((resolve, reject) => {
            refund(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    chart({ commit }, type) {
        return new Promise((resolve, reject) => {
            chart({ type: type }).then(res => {
                resolve(res.data.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    remove({ commit }, id) {
        return new Promise((resolve, reject) => {
            remove(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    setlist({ commit }, data) {
        commit("SET_SERVICES_LIST", data)
    },

    updateSelectedItem({ commit }, data) {
        commit("UPDATE_SERVICES_LIST", data)
    },

    removeSelectedList({ commit }, id) {
        commit("ROMEVE_SERVICES_ITEM", id)
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    emptyList({ commit }) {
        commit("EMPTY_SERVICES_LIST");
    },

}
