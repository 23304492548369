import Layout from '@/layouts'

const paymentType = {
  path: '/normaCost',
  component: Layout,
  name: 'normaCost',
  redirect: '/normaCost/index',
  children: [{
    path: '/normaCost/index',
    component: () => import('@/views/normaCost/index'),
    name: 'normaCost.index',
    meta: {
      title: 'Норма расходов'
    }
  }, ]
}

export default paymentType