export const filter = {
    id: "",
    name: "",
    total_price: "",
    order_count: "",
    search: "",
    created_at: "",
    updated_at: "",
    orders_from: '',
    orders_to: '',
};
