<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Продукт'"
    filterable
    clearable
    :size="size"
    class="d-block"
  >
    <el-option 
      v-for="product in products"
      :key="product.id"
      :label="product.name"
      :value="product.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [
    inventory
  ],
  mounted() {
    if (this.products && this.products.length === 0) {
      this.getInventory();
    }
  },
  computed: {
    ...mapGetters({
      products: 'products/inventory'
    })
  },
  methods: {
    ...mapActions({
      getInventory: 'products/inventory'
    })
  }
}
</script>