import { index, show, relation_show, store, update, destroy, updateOrder, updateStatus, setSigns, print, chart, patientList, informalPatientList, updateOrderDoctor, returnInformalDoctor } from '../../../api/orders';
import {updateHospitalDoctor} from "@/api/hospitalizationService";

export const actions = {

    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.orders.data);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.orders.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    patientList({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            patientList(params).then(res => {
                commit("SET_PATIENT_LIST", res.data.data.orders.data);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.orders.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    informalPatientList({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            informalPatientList(params).then(res => {
                commit("SET_INFORMAL_PATIENT_LIST", res.data.data.orders);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.data.order);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateOrderDoctor({ commit }, id) {
        return new Promise((resolve, reject) => {
            updateOrderDoctor(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateHospitalDoctor({ commit }, id) {
        return new Promise((resolve, reject) => {
            updateHospitalDoctor(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    returnInformalDoctor({ commit }, id) {
        return new Promise((resolve, reject) => {
            returnInformalDoctor(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    edit({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.data.order);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    print({ commit }, data) {
        return new Promise((resolve, reject) => {
            print(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    relationShow({ commit }, data) {
        return new Promise((resolve, reject) => {
            relation_show(data).then(res => {
                commit("SET_MODEL_RELATION", res.data.data.order);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    setSigns({ commit }, data) {
        return new Promise((resolve, reject) => {
            setSigns(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateOrder({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateOrder(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateStatus({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateStatus(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },

    updateColumnNew({ commit }, obj) {
        commit('UPDATE_COLUMN_NEW', obj)
    },

    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    chart({ commit }, type) {
        return new Promise((resolve, reject) => {
            chart({ type: type }).then(res => {
                resolve(res.data.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

}