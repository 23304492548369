import {
  index,
  show,
  update,
  destroy,
  search,
  inventory,
} from "@/api/debtors";

export const actions = {
  index({commit}, params = {}) {
    return new Promise((resolve, reject) => {
      index(params)
        .then((res) => {
          commit("SET_LIST", res.data.data.debtors.data);
          commit("UPDATE_PAGINATION", {
            key: "total",
            value: res.data.data.debtors.pagination.total,
          });
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  inventory({
    commit
  }, params = {}) {
    return new Promise((resolve, reject) => {
      inventory(params)
        .then((res) => {
          commit("SET_INVENTORY", res.data.data.debtors.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  show({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then((res) => {
          commit("SET_MODEL", res.data.data.debtor);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  update({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  destroy({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  updateSort({
    commit
  }, sort) {
    commit("SET_SORT", sort);
  },

  updateFilter({
    commit
  }, filter) {
    commit("SET_FILTER", JSON.parse(JSON.stringify(filter)));
  },

  updateColumn({
    commit
  }, obj) {
    commit("UPDATE_COLUMN", obj);
  },
  updatePagination({
    commit
  }, pagination) {
    commit("UPDATE_PAGINATION", pagination);
  },

  refreshData({
    commit
  }) {
    return new Promise((resolve, reject) => {
      commit("REFRESH");
      resolve();
    });
  },
  empty({
    commit
  }) {
    return new Promise((resolve, reject) => {
      commit("EMPTY_MODEL");
      resolve();
    });
  },

  search({
    commit
  }, params = {}) {
    return new Promise((resolve, reject) => {
      search(params)
        .then((res) => {
          if (res.data.data.patients)
            commit("SET_SEARCH_LIST", res.data.data.debtors);
          else commit("SET_SEARCH_LIST", []);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  emptySearch({
    commit
  }) {
    commit("EMPTY_SEARCH");
  },

};