import request from '../utils/request'


export function index(params) {
  return request({
    url: '/insurance-company',
    method: 'get',
    params
  })
}

export function inventory(params) {
  return request({
    url: '/insurance-company/inventory',
    method: 'get',
    params
  })
}

export function inventoryAll(params) {
  return request({
    url: '/partner-clinic/inventory-all',
    method: 'get',
    params
  })
}

export function show(id) {
  return request({
    url: `/insurance-company/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/insurance-company',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/insurance-company/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/insurance-company/${id}`,
    method: 'delete',
  })
}