import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    debt_type: {
        show: true,
        title: i18n.t('message.debt_type'),
        sortable: true,
        column: 'debt_type'
    },
    total_price: {
        show: true,
        title: i18n.t('message.total_amount'),
        sortable: true,
        column: 'total_price'
    },
    paid_price: {
        show: true,
        title: i18n.t('message.paid'),
        sortable: false,
        column: 'paid_price'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at',
    },
    partner_clinic: {
        show: true,
        title: i18n.t('message.partner_clinic'),
        sortable: true,
        column: 'partner_clinic'
    },
    partner_doctor: {
        show: true,
        title: i18n.t('message.partner_doctor'),
        sortable: true,
        column: 'partner_doctor'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};