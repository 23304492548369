import Layout from '@/layouts'

const couriersRouter = {
    path: '/couriers',
    component: Layout,
    name: 'couriers',
    redirect: '/couriers/index',
    children: []
}

export default couriersRouter