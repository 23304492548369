import Layout from '@/layouts'

const schedule = {
    path: '/schedules',
    component: Layout,
    name: 'schedules',
    redirect: '/schedules/index',
    children: [
      {
        path: '/schedules/index',
        component: () => import('@/views/schedule/index'),
        name: 'schedules.index',
        meta: { title: 'График'}
      },
    ]
  }

export default schedule
