<template>
  <div :id="id"></div>
</template>

<script>
import { DayPilot } from "daypilot-pro-vue";

export default {
  name: "HelloWorld",
  props: ["id", "config"],
  mounted: function() {
    this.control = new DayPilot.Scheduler(this.id, this.config).init();
    this.control.scrollTo(new DayPilot.Date.today());
  }
};
</script>