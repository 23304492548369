import Layout from '@/layouts'

const adminPanel = {
    path: '/adminPanel',
    component: Layout,
    name: 'adminPanels',
    redirect: '/adminPanel/index',
    children: [
        {
            path: '/adminPanel/index',
            component: () => import('@/views/adminPanel/index'),
            name: 'adminPanel.index',
            meta: { title: 'Команды администратора'}
        },
    ]
}

export default adminPanel