import { actions } from './auto_write_off/actions'
import { getters } from './auto_write_off/getters'
import { state } from './auto_write_off/state'
import { mutations } from './auto_write_off/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
