import Layout from '@/layouts'

const patient = {
  path: '/orderServiceTerm',
  component: Layout,
  name: 'orderServiceTerm',
  redirect: '/orderServiceTerm/index',
  children: [
    {
      path: '/orderServiceTerm/index',
      component: () => import('@/views/orderServiceTerm/index'),
      name: 'orderServiceTerm.index',
      meta: { title: 'Типы расхода'}
    },
  ]
}

export default patient