export const filter = {
    search: "",
    id: "",
    fullName: "",
    summa: "",
    contractNumber: "",
    zametka: "",
    typePatsent: "",
    doctor: "",
    data: ""
};
