import { i18n } from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    patient_id: {
        show: true,
        title: i18n.t('message.patient'),
        sortable: true,
        column: 'patient_id'
    },
    day: {
        show: true,
        title: i18n.t('message.date_0'),
        sortable: true,
        column: 'day'
    },
    // notes: {
    //     show: true,
    //     title: i18n.t('message.notes'),
    //     sortable: true,
    //     column: 'notes'
    // },
    service: {
        show: true,
        title: i18n.t('message.service'),
        sortable: true,
        column: 'service'
    },
    doctor: {
        show: true,
        title: i18n.t('message.doctor'),
        sortable: true,
        column: 'doctor'
    },
    condition_id: {
        show: true,
        title: i18n.t('message.condition'),
        sortable: true,
        column: 'condition_id'
    },
    // partner_doctor_id: {
    //     show: false,
    //     title: i18n.t('message.partner_doctor'),
    //     sortable: true,
    //     column: 'partner_doctor_id'
    // },
    from_hour: {
        show: true,
        title: i18n.t('message.from_hours'),
        sortable: false,
        column: 'from_hour'
    },
    duration: {
        show: true,
        title:  i18n.t('message.duration'),
        sortable: false,
        column: 'duration'
    },
    created_at: {
        show: false,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at',
    },
    updated_at: {
        show: false,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'updated_at',
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};