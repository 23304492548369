import {sort} from "../properties/sort";
import {filter} from "./properties/filter";
import {pagination} from "../properties/pagination";
import {model} from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, regions) => {
        state.list = regions
    },
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_INVENTORY: (state,list) => state.inventory = list,
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_MODEL: (state,model) => {
        state.model = model;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
        state.sort = JSON.parse( JSON.stringify( sort ));
        state.pagination = JSON.parse( JSON.stringify( pagination ));
    },

    CREATE_MODEL: (state, model) => {
        state.list.unshift(model)
    },

    UPDATE_MODEL: (state, model) => {
        for (let i = 0; i < state.list.length; i++) {
            const element = state.list[i];
            if(element.id == model.id){
                state.list[i] = model;                
            }
        }
    },


    DELETE_MODEL: (state, id) => {
        for (const i in state.list) {
            if (state.list.hasOwnProperty(i)) {
                const element = state.list[i];
                if (element.id == id) {
                    state.list.splice(i,1)
                }
            }
        }
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.region.title = i18n.t('message.region');
        state.columns.code.title = i18n.t('message.code');
        state.columns.city_id.title = i18n.t('message.city');
        state.columns.state_id.title = i18n.t('message.country');
        state.columns.created_at.title = i18n.t('message.date');
        state.columns.updated_at.title = i18n.t('message.update_date');
        state.columns.settings.title = i18n.t('message.settings');     
    }
}
