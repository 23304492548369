import Layout from '@/layouts'

const paymentType = {
  path: '/ProductsWarehouses',
  component: Layout,
  name: 'ProductsWarehouses',
  redirect: '/ProductsWarehouses/index',
  children: [{
    path: '/ProductsWarehouses/index',
    component: () => import('@/views/ProductsWarehouses/index'),
    name: 'ProductsWarehouses.index',
    meta: {
      title: 'Продукты'
    }
  }, ]
}

export default paymentType