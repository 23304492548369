import { i18n } from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: "№",
    sortable: true,
    column: "id"
  },
  Warehouses1: {
    show: true,
    title: i18n.t('message.main_warehouses'),
    sortable: true,
    column: "Warehouses1"
  },
  warehouses2: {
    show: true,
    title: i18n.t('message.warehouse_movement'),
    sortable: true,
    column: "warehouses2"
  },
  wdata: {
    show: true,
    title: i18n.t('message.date_movement'),
    sortable: true,
    column: "wdata"
  },
  user: {
    show: true,
    title: i18n.t('message.users'),
    sortable: true,
    column: "user"
  },
  wlec: {
    show: true,
    title:  i18n.t('message.show_drug'),
    sortable: true,
    column: "wlec"
  },

  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: "settings"
  }
};