import { getToken } from "../../../utils/auth";

export const state = {
    token: getToken(),
    id: null,
    name: '',
    phone: '',
    branch: null,
    branches: [],
    owner_branch: [],
    current_branch: null,
    role: null,
    expires_in: 600000
};
