import { actions } from './clinicImage/actions'
import { getters } from './clinicImage/getters'
import { state } from './clinicImage/state'
import { mutations } from './clinicImage/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
