import { actions } from './supplierContract/actions';
import { getters } from './supplierContract/getters';
import { state } from './supplierContract/state';
import { mutations } from './supplierContract/mutations';

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};