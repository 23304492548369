import request from '../utils/request'


export function index(params) {
  return request({
    url: '/doctor_signs',
    method: 'get',
    params
  })
}
export function inventory(params) {
  return request({
    url: '/doctor_signs/inventory',
    method: 'get',
    params
  })
}


export function show(id) {
  return request({
    url: `/doctor_signs/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/doctor_signs',
    method: 'post',
    data
  })
}

export function update(params) {
  return request({
    url: `/doctor_signs/${params.id}`,
    method: 'post',
    data: params.data,
  })
}

export function destroy(id) {
  return request({
    url: `/doctor_signs/${id}`,
    method: 'delete',
  })
}