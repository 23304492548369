import request from "./../utils/request";

export function index(params) {
  return request({
    url: "/patients",
    method: "get",
    params,
  });
}

export function inventory(params) {
  return request({
    url: "/patients/inventory",
    method: "get",
    params,
  });
}
export function getLastId(params) {
  return request({
    url: '/patients/patients/getLastId',
    method: 'get',
    params
  })
} 

export function organizationPatients(params) {
  return request({
    url: "/organization/patients",
    method: "get",
    params,
  });
}

export function myPatinets(params) {
  return request({
    url: "/doctor/patients",
    method: "get",
    params,
  });
}

export function show(id) {
  return request({
    url: `/patients/${id}`,
    method: "get",
  });
}

export function lastIdPatent(params) {
  return request({
    url: `/patients/lastIdPatient`,
    method: "post",
    params,
  });
}

export function store(data) {
  return request({
    url: "/patients",
    method: "post",
    data,
  });
}

export function update(data) {
  return request({
    url: `/patients/${data.id}`,
    method: "put",
    data,
  });
}

export function destroy(id) {
  return request({
    url: `/patients/${id}`,
    method: "delete",
  });
}
export function loadPatientLastOrderServices(id) {
  return request({
    url: `/patients/loadPatientLastOrderServices/${id}`,
    method: "get",
  });
}

export function search(params) {
  return request({
    url: "/patients/patients/search",
    method: "get",
    params,
  });
}


