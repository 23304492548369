import { i18n } from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: "id"
      },
      fullName: {
        show: true,
        title: i18n.t('message.patient'),
        sortable: true,
        column: "fullName"
      },
      summa: {
        show: true,
        title: i18n.t('message.total_amount'),
        sortable: true,
        column: "summa"
      },
      contractNumber: {
        show: true,
        title: i18n.t('message.paid'),
        sortable: true,
        column: "contractNumber"
      },
      zametka: {
        show: true,
        title: i18n.t('message.notes'),
        sortable: true,
        column: "zametka"
      },
      typePatsent: {
        show: true,
        title: i18n.t('message.number_card'),
        sortable: true,
        column: "typePatsent"
      },
      doctor: {
        show: true,
        title: i18n.t('message.doctor'),
        sortable: true,
        column: "doctor"
      },
      data: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: "data"
      },
      settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: "settings"
      }
};
