<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="'Услуги'"
    filterable
    @change="emitParentEvent"
    clearable
    :size="size"
    class="d-block"
  >
    <el-option
      v-for="service in selected_services"
      :key="service.id"
      :label="service.name"
      :value="service.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["order_id"],
  data() {
    return {
      selected_services: [],
    };
  },
  watch: {
    order_id: function (newVal, oldVal) {
      this.fun(newVal);
      this.$emit("input", this.selected);
    },
  },
  mounted() {
    if (this.services && this.services.length === 0) {
      this.getInventory();
      this.selected_services = this.services;
    }
  },
  methods: {
    
    ...mapActions({
      getInventory: "services/inventory",
    }),
    fun(val) {
      this.getInventory({ order_id: val }).then((res) => {
        this.selected_services = res.data.services.data;
      });
    },
    emitParentEvent() {
      this.$emit("parent-event", this.selected);
    },
  },
  computed: {
    ...mapGetters({
      services: "services/inventory",
    }),

    filtered: function () {
      if (this.order_id != null) {
        this.fun(this.order_id);
        return this.services;
      } else {
        return this.services;
      }
    },
  },
};
</script>