import Layout from "@/layouts";

const documentType = {
  path: "/scores",
  component: Layout,
  name: "scores",
  redirect: "/scores/index",
  children: [
    {
      path: "/scores/index",
      component: () => import("@/views/scores/index"),
      name: "scores.index",
      meta: { title: "Счета" },
    },
  ],
};

export default documentType;
