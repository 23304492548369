import Layout from '@/layouts'

const city = {
  path: '/printHistory',
  component: Layout,
  name: 'printHistory',
  redirect: '/printHistory/index',
  children: [{
    path: '/printHistory/index',
    component: () => import('@/views/printHistory/index'),
    name: 'printHistory.index',
    meta: {
      title: 'История Печати'
    }
  }, ]
}

export default city