import request from "../utils/request";

export function index(params) {
  return request({
    url: "/sick_numbers",
    method: "get",
    params,
  });
}

export function inventory(params) {
  return request({
    url: "/sick_number/inventory",
    method: "get",
    params,
  });
}

export function show(id) {
  return request({
    url: `/sick_numbers/${id}`,
    method: "get",
  });
}

export function store(data) {
  return request({
    url: "/sick_numbers",
    method: "post",
    data,
  });
}

export function update(data) {
  return request({
    url: `/sick_numbers/${data.id}`,
    method: "put",
    data,
  });
}

export function destroy(id) {
  return request({
    url: `/sick_numbers/${id}`,
    method: "delete",
  });
}