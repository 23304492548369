import { actions } from './tag/actions'
import { getters } from './tag/getters'
import { state } from './tag/state'
import { mutations } from './tag/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
