import { actions } from './debts/actions'
import { getters } from './debts/getters'
import { state } from './debts/state'
import { mutations } from './debts/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
