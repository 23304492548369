import { i18n } from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: "№",
    sortable: true,
    column: "id"
  },
  mainwarehouse_id: {
    show: true,
    title: i18n.t('message.main_warehouses'),
    sortable: true,
    column: "mainwarehouse_id"
  },
  warehouse_id: {
    show: true,
    title:  i18n.t('message.warehouse_movement'),
    sortable: true,
    column: "warehouse_id"
  },
  created_at: {
    show: true,
    title: i18n.t('message.date_requirement'),
    sortable: true,
    column: "created_at"
  },
  user_id: {
    show: true,
    title: i18n.t('message.users'),
    sortable: true,
    column: "user_id"
  },
  wlec: {
    show: true,
    title:  i18n.t('message.show_drug'),
    sortable: true,
    column: "wlec"
  },
  status: {
    show: true,
    title: i18n.t('message.status'),
    sortable: true,
    column: "status"
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: "settings"
  }
};