export const getters = {
    token: state => state.token,
    phone: state => state.phone,
    name: state => state.name,
    branch: state => state.branch,
    branches: state => state.branches,
    current_branch: state => state.current_branch,
    owner_branch: state => state.owner_branch,
    role: state => state.role,
    id: state => state.id,
    permissions: state => { return (state.role) ? state.role.permissions : [] },
    expires_in: state => state.expires_in
};
