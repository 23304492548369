import Layout from '@/layouts'

const condition = {
    path: '/contracts',
    component: Layout,
    name: 'contracts',
    redirect: '/contracts/index',
    children: [
      {
        path: '/contracts/index',
        component: () => import('@/views/contracts/index'),
        name: 'contracts.index',
        meta: { title: 'Состояние'}
      },
    ]
  }

export default condition
