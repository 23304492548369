import { i18n } from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    transactionable_id: {
        show: true,
        title: i18n.t('message.type_expence'),
        sortable: true,
        column: 'transactionable_id'
    },
    payment_type_id: {
        show: true,
        title: i18n.t('message.payment_type'),
        sortable: true,
        column: 'payment_type_id'
    },
    price: {
        show: true,
        title: i18n.t('message.amount'),
        sortable: true,
        column: 'price'
    },
    currency_id: {
        show: true,
        title: i18n.t('message.currency'),
        sortable: true,
        column: 'currency_id'
    },
    rate: {
        show: true,
        title: i18n.t('message.rate'),
        sortable: true,
        column: 'rate'
    },
    date: {
        show: true,
        title: i18n.t('message.date_0'),
        sortable: true,
        column: 'date'
    },
    comment: {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    user_id: {
        show: false,
        title: i18n.t('message.user'),
        sortable: true,
        column: 'user_id'
    },
    created_at: {
        show: false,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};