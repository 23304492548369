<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'лекарства'"
    filterable
    clearable

  >
    <el-option v-for="item in products" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [inventory],
  props: [ 'product_id'],
  mounted() {
    if (this.products && this.products.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    product_id:{
       handler: function (val, oldVal) { 
          this.selected = val;
          this.$emit("input", this.selected);
       },
       deep: true,
       immediate: true
    }
  },
  computed: {
    ...mapGetters({
      products: 'products/inventory',
    }),
    // filtered: function() {
    //   if (this.corps_id) {
    //     return this.products.filter((o) => {
    //       return this.corps_id == o.corps_id;
    //     })
    //   }else{
    //     return this.products;
    //   }
    // }
  },
  methods: {
    ...mapActions({
      getInventory: 'products/inventory',
    })
  },
};
</script>
