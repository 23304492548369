import { actions } from './invoiceInsurance/actions'
import { getters } from './invoiceInsurance/getters'
import { state } from './invoiceInsurance/state'
import { mutations } from './invoiceInsurance/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
