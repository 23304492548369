import request from '../utils/request'

// back-endda PatientScheduleController  
export function index(params) {
  	return request({
  	  url: '/patientSchedules',
  	  method: 'get',
        params
  	})
  }
// back-endda PatientScheduleController  
export function indexbunk(params) {
return request({
    url: '/hospitalRooms/withbunk',
    method: 'get',
    params
})
}
// back-endda PatientScheduleController  
  export function inventory(params) {
    return request({
      url: '/patientSchedules/inventory',
      method: 'get',
      params
    })
  }
  
export function show(id) {
    return request({
      url: `/patientSchedules/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/patientSchedules',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/patientSchedules/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/patientSchedules/${id}`,
      method: 'delete',
    })
  }
