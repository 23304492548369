export const getters = {
  list: state => state.list,
  model: state => state.model,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort,
  rules: state => state.rules,
  relation: state => state.relation,
  inventory: state => state.inventory
};