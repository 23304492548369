import Layout from '@/layouts'

const paymentType = {
  path: '/contractWarehouses',
  component: Layout,
  name: 'contractWarehouses',
  redirect: '/contractWarehouses/index',
  children: [{
    path: '/contractWarehouses/index',
    component: () => import('@/views/contractWarehouses/index'),
    name: 'contractWarehouses.index',
    meta: {
      title: 'Продукты'
    }
  }, ]
}

export default paymentType