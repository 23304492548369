// closeDrawer, filterForm, sort, pagination
// add function:
// add create modal: @close="reloadIfChanged"
// add create companent: :reloadModel="reopenUpdate" @c-close="reloadIfChanged('drawerCreate')"
// add update modal: @open="reopenUpdate = true" @close="reloadIfChanged('reopenUpdate')" @closed="emptyModel"
// add update component: :reloadModel="reopenUpdate" @open="reopenUpdate = true" @c-close="closeDrawer"
export default {
    data() {
        return {
            reloadList: false,
            filterForm: {},
        }
    },
    computed: {},
    created() {
        this.filterForm = JSON.parse(JSON.stringify( this.filter ));
        this.debouncedGetAnswer = _.debounce(this.fetchData, 500)
    },
    mounted() {},
    watch: {
        filterForm: {
            handler: async function(newVal, oldVal) {
                await this.updatePagination({ key: "page", value: 1 });
                await this.updateFilter(newVal);
                this.debouncedGetAnswer()                    
            },
            deep: true,
        },
        sort: {
            handler: async function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.debouncedGetAnswer()
                }
            }
        },
        'pagination.page': {
            handler: function(newVal, oldVal) {
                if (newVal != oldVal && this.debouncedGetAnswer) {   
                    this.debouncedGetAnswer()
                }
            },
            deep: true,
            immediate: true
        },
        'pagination.per_page': {
            handler: function(newVal, oldVal) {
                if (newVal != oldVal && this.debouncedGetAnswer) {   
                    this.debouncedGetAnswer()
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        closeDrawer(drawer) {
            
            this[drawer.drawer] = false;
            if (this.reloadList === true) {
                this.fetchData();
            }
            if(this[drawer.emptyModel]){
                this.emptyModel()
            }
        },
        listChanged() {
            this.reloadList = true;
        },
        afterFetchData() {
            this.reloadList = false;
        },
        reloadIfChanged(modal) {
            this[modal] = false;
            if (this.reloadList === true) {
                this.fetchData();
                this.afterFetchData();
            }
        },
        parent() {
            return this.$parent.$parent
        },
    },
}