import request from '../utils/request'


export function index(params) {
    return request({
        url: '/dailyProcessReport',
        method: 'get',
        params
    })
}

export function show(id) {
    return request({
        url: `/dailyProcessReport/${id}`,
        method: 'get'
    })
}

export function store(data) {
    return request({
        url: '/dailyProcessReport',
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: `/dailyProcessReport/${data.id}`,
        method: 'put',
        data
    })
}

export function destroy(id) {
    return request({
        url: `/dailyProcessReport/${id}`,
        method: 'delete',
    })
}