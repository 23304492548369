import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    name: {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    rate: {
        show: true,
        title: i18n.t('message.rate'),
        sortable: true,
        column: 'rate'
    },
    reverse: {
        show: true,
        title: i18n.t('message.reverse'),
        sortable: true,
        column: 'reverse'
    },
    reversed_rate: {
        show: true,
        title: i18n.t('message.reverse_rate'),
        sortable: true,
        column: 'reversed_rate'
    },
    symbol: {
        show: true,
        title: i18n.t('message.symbol'),
        sortable: true,
        column: 'symbol'
    },
    code: {
        show: true,
        title: i18n.t('message.code'),
        sortable: true,
        column: 'code'
    },
    active: {
        show: true,
        title: i18n.t('message.status_0'),
        sortable: true,
        column: 'active'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
