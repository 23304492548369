export const filter = {
    id: "",
    name: "",

    search: "",
    created_at: "",
    updated_at: "",
    start_date: '',
    end_date: '',
};
