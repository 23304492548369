import request from './../utils/request'


export function index(params) {
  	return request({
  	  url: '/priceLists',
  	  method: 'get',
        params
  	})
  }
