import request from '../utils/request'


export function index(params) {
  	return request({
  	  url: '/patientAge',
  	  method: 'get',
        params
  	})
  }

  
export function show(id) {
    return request({
      url: `/patientAge/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/patientAge',
      method: 'post',
      data
    })
  }


  export function update(data) {
    return request({
      url: `/patientAge/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/patientAge/${id}`,
      method: 'delete',
    })
  }
