import request from '../utils/request'

export function dashboardInfo() {
  return request({
    url: `/dashboard/dashboardInfo`,
    method: 'get'
  })
}



