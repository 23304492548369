import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts/index'

Vue.use(VueRouter)

var routes = [
  {
    path: '/login',
    component: () => import('@/views/auth/login'),
    name: 'login',
  },
  {
    path: '/test',
    component: () => import('@/views/test/test'),
    name: 'test',
  },
  {
    path: '/',
    component: Layout,
    redirect: '/',
    children: [
      {
        path: '/',
        component: () => import('@/views/Home'),
        name: 'home',
        meta: { title: 'Главный страница ' }
      }
    ]
  },
]

/**
 * Load all routes from modulea folder
 */
const request = require.context('./modules', true, /.js/);

request.keys().map(module => {
    routes = routes.concat(request(module).default);
});

const router = new VueRouter({
    routes: routes
  })

export default router;
