import Layout from '@/layouts'

const schedule = {
  path: '/productResidues',
  component: Layout,
  name: 'productResidues',
  redirect: '/productResidues/index',
  children: [{
    path: '/productResidues/index:id',
    component: () => import('@/views/productResidues/index'),
    name: 'productResidues.index',
    meta: {
      title: 'Кабинет медсестра '
    }
  }, ]
}

export default schedule