import { actions } from './product/actions';
import { getters } from './product/getters';
import { state } from './product/state';
import { mutations } from './product/mutations';

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};