import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    patient_id: {
        show: true,
        title: i18n.t('message.patient'),
        sortable: false,
        column: 'patient_id'
    },
    date_time: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'date_time'
    },
    service_id: {
        show: true,
        title: i18n.t('message.services'),
        sortable: false,
        column: 'date_time'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
 
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};