export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите краткое наименование ', trigger: 'blur' },
    ],
    full_name: [
        { required: true, message: 'Пожалуйста, введите полное наименование ', trigger: 'blur' },
    ],
    code: [
        { required: true, message: 'Пожалуйста, введите цифровой код', trigger: 'blur' },
    ],
    status: [
        { required: true, message: 'Пожалуйста, введите статус', trigger: 'blur' },
    ],
};
