import { actions } from './dailyTreatment/actions'
import { getters } from './dailyTreatment/getters'
import { state } from './dailyTreatment/state'
import { mutations } from './dailyTreatment/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
