<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Пациент'"
    filterable
    clearable
    :size="size"
    class="d-block"
    remote
    :remote-method="searchPatientWithLoad"
  >
    <el-option
      v-for="(patient,index) in patients"
      :key="'patients-' + index"
      :label="patient.surname + ' ' + patient.first_name" 
      :value="patient.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import mix from "@/utils/mixins/components/inventory";

export default {
  name: "patients",
  mixins: [mix],
  props: ["id"],
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.updateInventory(/*{ per_page: 30 }*/)
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.patients && this.patients.length === 0)
      this.updateInventory(/*{ per_page: 30 }*/);
  },
  computed: {
    ...mapGetters({
      patients: "patients/inventory"
    })
  },
  methods: {
    ...mapActions({
      updateInventory: "patients/inventory"
    }),
    searchPatientWithLoad(val) {
      this.searchPatient(val);
    },
    searchPatient: _.debounce(function (val) {
      this.loadingData = true;
      this.updateInventory({ search_patient: val, per_page: 30 }).finally(() => {
        this.loadingData = false;
      });
    }, 500),
  }
};
</script>