export const model = {
    id: null,
    name: "",
    phone_number: null,
    partner_clinic_id: null,
    patronymic: "",
    note: "",
    id_code: "",
    created_at: '',
    updated_at: ''
};