import {sort} from "../properties/sort";
import {filter} from "./properties/filter";
import {pagination} from "../properties/pagination";
import {model} from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, patientSchedules) => {
        state.list = patientSchedules
    },
    SET_DATAROOM: (state, hospitalRooms) => {
        state.dataroom = hospitalRooms
    },
    SET_EVENTS: (state, patientSchedules) => {
        state.events = patientSchedules
    },
    SET_BUNK: (state, bunkDetails) => {
        state.bunk = bunkDetails
    },
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_INVENTORY: (state,list) => state.inventory = list,
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_MODEL: (state,model) => {
        state.model = model;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.bunk = [];
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
        state.sort = JSON.parse( JSON.stringify( sort ));
        state.pagination = JSON.parse( JSON.stringify( pagination ));
    },
    SET_SAVING: (state,val) => {
        state.saving = val;
    },
    CHANGE_LIST: (state,item) => {
        for (let index = 0; index < state.list.length; index++) {
            const element = state.list[index];
            if (element.id == item.id) {
                state.list[index].color = item.color;
            }
        }
    },
    UPDATE_LANG_COLUMN: (state) => {
            state.columns.id.title = "№";
        
            state.columns.bunk_id.title = i18n.t('message.corps');
        
            state.columns.resource.title = i18n.t('message.corps');
            state.columns.start.title = i18n.t('message.corps');
            state.columns.end.title = i18n.t('message.class');
            state.columns.text.title = i18n.t('message.floor');
            state.columns.color.title = i18n.t('message.color'); 
    }
};
