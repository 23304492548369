import request from './../utils/request'


export function index(params) {
  	return request({
  	  url: '/conclusions',
  	  method: 'get',
        params
  	})
  }

  export function inventory(params) {
  	return request({
  	  url: '/conclusion/inventory',
  	  method: 'get',
        params
  	})
  }
  
export function show(id) {
    return request({
      url: `/conclusions/${id}`,
      method: 'get'
    })
  }

  export function sendToHistory(data) {
    return request({
      url: `/conclusion/sendToHistory`,
      method: 'POST',
      data
    })
  }

  export function store(data) {
    return request({
      url: '/conclusions',
      method: 'post',
      data
    })
  }

  export function getHeader(data) {
    return request({
      url: '/conclusions/get-header',
      method: 'post',
      data
    })
  }

  export function update(datas) {
    let data = datas.data
    let id = datas.id
    return request({
      url: `/conclusions/${id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/conclusions/${id}`,
      method: 'delete',
    })
  }

  export function print(params) {
    return request({
        url: `/conclusion/print`,
        method: 'get',
        params
    })
}

export function printCenter(params) {
  return request({
    url: '/conclusion/print-center',
    method: 'get',
    params
  })
}

export function printCenterHistory(params) {
  return request({
    url: '/conclusion/print-center-history',
    method: 'get',
    params
  })
}

export function patientConculation(params) {
  return request({
    url: '/conclusion/patient-conculation',
    method: 'get',
  params
  })
}

export function patientConculationOld(params) {
  return request({
    url: '/conclusion/patient-conculation-old',
    method: 'get',
  params
  })
}
