import Layout from '@/layouts'

const tag = {
    path: '/tags',
    component: Layout,
    name: 'tags',
    redirect: '/tags/index',
    children: [
      {
        path: '/tags/index',
        component: () => import('@/views/tag/index'),
        name: 'tags.index',
        meta: { title: 'Теги'}
      },
    ]
  }

export default tag
