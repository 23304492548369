import request from '../utils/request'


export function index(params) {
  	return request({
  	  url: '/hospitalRooms',
  	  method: 'get',
        params
  	})
  }

export function indexbunk(params) {
  return request({
    url: '/hospitalRooms/withbunk',
    method: 'get',
    params
  })
}

  export function inventory(params) {
    return request({
      url: '/hospitalRooms/inventory',
      method: 'get',
      params
    })
  }
  
export function show(id) {
    return request({
      url: `/hospitalRooms/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/hospitalRooms',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/hospitalRooms/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/hospitalRooms/${id}`,
      method: 'delete',
    })
  }
