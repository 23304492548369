export const rules = {
    patient_id: [
        { required: true, message: 'Пожалуйста, выберите пациент ', trigger: 'change' },
    ],
    
    price: [
        { required: true, message: 'Пожалуйста, выберите  цена', trigger: 'change' },
    ],

    payment_type_id: [
        { required: true, message: 'Пожалуйста, выберите  тип оплаты', trigger: 'change' },
    ],

  
};
