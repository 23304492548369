import Layout from "@/layouts";

const costTransaction = {
  path: "/costTransactions",
  component: Layout,
  name: "costTransactions",
  redirect: "/costTransactions/index",
  children: [
    {
      path: "/costTransactions/index",
      component: () => import("@/views/costTransaction/index"),
      name: "costTransactions.index",
      meta: { title: "Платежи за расходы" },
    },
  ],
};

export default costTransaction;
