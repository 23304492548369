import {getCurrentTab} from './../utils/local_storage'
export const state =  {
    money: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false,
        prefix: '',
        suffix: ' сум',
    },
    noSuffixMoney: {
        decimal: '.',
        thousands: ',',
        precision: 0,
        masked: false,
        prefix: '',
        suffix: '',
    },
    newMoney: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false,
        prefix: '',
        suffix: '',
    },
    mobileMenu: false,
    current_tab: getCurrentTab()
}