import Layout from '@/layouts'

const paymentType = {
  path: '/stocksWarehouses',
  component: Layout,
  name: 'stocksWarehouses',
  redirect: '/stocksWarehouses/index',
  children: [{
    path: '/stocksWarehouses/index',
    component: () => import('@/views/stocksWarehouses/index'),
    name: 'stocksWarehouses.index',
    meta: {
      title: 'Продукты'
    }
  },
  {
    path: '/stocksWarehouses/consuption',
    component: () => import('@/views/stocksWarehouses/consuption'),
    name: 'stocksWarehouses.consuption',
    meta: {
      title: 'Продукты'
    }
  },
 ]
}

export default paymentType