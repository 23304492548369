export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
        { min: 3, max: 255, message: 'Длина должна быть от 3 до 255', trigger: 'blur' }
    ],
    phone: [
        { required: true, validator: 'Пожалуйста, введите телефон', trigger: 'blur'},
        { min: 13, max: 13, message: 'Длина должна быть 13', trigger: 'blur' }
    ],
    password: [
        { required: true, message: 'Пожалуйста, введите пароль', trigger: 'blur' },
        { min: 6, max: 255, message: 'Длина должна быть от 3 до 255', trigger: 'blur' },
    ],
    role_id: [
        { required: true, message: 'Пожалуйста, введите Роль', trigger: 'blur' }
    ],
    id_code: [
        { required: false, message: 'Пожалуйста, введите Идентификационный код', trigger: 'blur' }
    ],
};
