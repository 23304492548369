import Layout from '@/layouts'

const schedule = {
  path: '/stockBunk',
  component: Layout,
  name: 'stockBunk',
  redirect: '/stockBunk/index',
  children: [{
    path: '/stockBunk/index',
    component: () => import('@/views/stockBunk/index'),
    name: 'stockBunk.index',
    meta: {
      title: 'Кабинет медсестра '
    }
  }, ]
}

export default schedule