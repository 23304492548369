export const getters = {
    list: state => state.list,
    columns: state => state.columns,
    model: state => state.model,
    filter: state => state.filter,
    pagination: state => state.pagination,
    show_pagination: state => state.show_pagination,
    reports: state => state.reports,
    sort: state => state.sort,
    rules: state => state.rules,
    selected_partner_doctor_report: state => state.selected_partner_doctor_report,
};
