export const model = {
    id: null,
    part: '',
    part_id: null,
    date: null,
    payment_type_id: null,
    score_id: null,
    price: null,
    comment: null,
    created_at: '',
    updated_at: ''
};
