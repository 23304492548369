import { actions } from './debtor/actions'
import { getters } from './debtor/getters'
import { state } from './debtor/state'
import { mutations } from './debtor/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
