export const rules = {
    code: [
        { required: true, message: 'Пожалуйста, введите код ', trigger: 'blur' },
    ],
    phone: [
        { required: true, message: 'Пожалуйста, введите телефон номер ', trigger: 'blur' },
    ],
    image: [
        { required: true, message: 'Пожалуйста, введите телефон номер ', trigger: 'blur' },
    ],
    gender: [
        { required: true, message: 'Пожалуйста, введите пол ', trigger: 'blur' },
    ],
    street: [
        { required: true, message: 'Пожалуйста, введите улица ', trigger: 'blur' },
    ],
    surname: [
        { required: true, message: 'Пожалуйста, введите фамилия ', trigger: 'blur' },
    ],
    district: [
        { required: true, message: 'Пожалуйста, введите район ', trigger: 'blur' },
    ],
    born_date: [
        { required: true, message: 'Пожалуйста, введите дата рождения ', trigger: 'blur' },
    ],
    given_date: [
        { required: true, message: 'Пожалуйста, введите заданная дата ', trigger: 'blur' },
    ],
    first_name: [
        { required: true, message: 'Пожалуйста, введите имя ', trigger: 'blur' },
    ],
    born_place: [
        { required: true, message: 'Пожалуйста, введите место рождения ', trigger: 'blur' },
    ],
    city_id: [
        { required: true, message: 'Пожалуйста, введите город ', trigger: 'blur' },
    ],
    region_id: [
        { required: true, message: 'Пожалуйста, введите область ', trigger: 'blur' },
    ],
    state_id: [
        { required: true, message: 'Пожалуйста, введите штат ', trigger: 'blur' },
    ],
    citizenship_id: [
        { required: true, message: 'Пожалуйста, введите гражданство ', trigger: 'blur' },
    ],
    nationality_id: [
        { required: true, message: 'Пожалуйста, введите национальность ', trigger: 'blur' },
    ],
    passport_number: [
        { required: true, message: 'Пожалуйста, введите номер паспорта ', trigger: 'blur' },
    ],

};
