import request from './../utils/request';

export function index(params) {
  return request({
    url: '/suppliers',
    method: 'GET',
    params
  })
}

export function show(id) {
  return request({
    url: `/suppliers/${id}`,
    method: 'GET'
  })
}

export function store(data) {
  return request({
    url: '/suppliers',
    method: 'POST',
    data
  })
}

export function update(data) {
  return request({
    url: `/suppliers/${data.id}`,
    method: 'PUT',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/suppliers/${id}`,
    method: 'DELETE',
  })
}

export function destroyContact(id) {
  return request({
    url: `/suppliers/contact/${id}`,
    method: 'DELETE',
  })
}

export function destroyBankAccount(id) {
  return request({
    url: `/suppliers/bank-account/${id}`,
    method: 'DELETE',
  })
}

export function inventory(params) {
  return request({
    url: `/suppliers/inventory`,
    method: 'GET',
    params
  });
}