export const rules = {
  number: [
    { required: true, message: 'Пожалуйста укажите номер договора', trigger: 'blur' },
  ],
  begin_date: [
    { required: true, message: 'Пожалуйста укажите дату', trigger: 'blur' }
  ],
  sum: [
    { required: true, message: 'Пожалуйста укажите сумму договора', trigger: 'blur' },
  ],
  supplier_id: [
    { required: true, message: 'Пожалуйста укажите поставщика', trigger: 'blur' }
  ],
  status_id: [
    { required: true, message: 'Пожалуйста укажите статус', trigger: 'blur' }
  ]
};