import { columns } from './properties/columns'
import { filter } from './properties/filter'
import { statues } from './properties/statues'
import { pagination } from "../properties/pagination";
import { sort } from "../properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";


export const state = {
    list: [],
    inventory: [],
    inventory_general: [],
    doctor_list: [],
    free_list: [],
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),
    statues: statues,
    rules: rules,
    permissions: [],
    spess: [],
};
