import { actions } from './patientHistory/actions'
import { getters } from './patientHistory/getters'
import { state } from './patientHistory/state'
import { mutations } from './patientHistory/mutations'


export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
