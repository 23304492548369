import { actions } from './user/actions'
import { getters } from './user/getters'
import { state } from './user/state'
import { mutations } from './user/mutations'

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
