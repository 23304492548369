import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: '№',
        sortable: true,
        column: 'id'
    },
    name: {
        show: true,
        title: i18n.t('message.first_name'),
        sortable: true,
        column: 'name'
    },
    surname: {
        show: false,
        title: i18n.t('message.surname'),
        sortable: true,
        column: 'surname'
    },
    patronymic: {
        show: false,
        title: i18n.t('message.patronymic'),
        sortable: true,
        column: 'patronymic'
    },
    email: {
        show: false,
        title: i18n.t('message.email'),
        sortable: true,
        column: 'email'
    },
    phone: {
        show: true,
        title: i18n.t('message.phone'),
        sortable: true,
        column: 'phone'
    },
    password: {
        show: true,
        title: i18n.t('message.password'),
        sortable: true,
        column: 'password'
    },
    phone_two: {
        show: false,
        title: i18n.t('message.phone_two'),
        sortable: false,
        column: 'phone_two'
    },
    role_id: {
        show: false,
        title: i18n.t('message.role'),
        sortable: true,
        column: 'role_id'
    },
    role_name: {
        show: true,
        title: i18n.t('message.role_name'),
        sortable: false,
        column: 'role_name'
    },
    doctor_services: {
        show: false,
        title: i18n.t('message.doctor_service'),
        sortable: false,
        column: 'doctor_services'
    },
    gender: {
        show: false,
        title: i18n.t('message.gender'),
        sortable: false,
        column: 'gender'
    },
    id_code: {
        show: false,
        title: i18n.t('message.id_code'),
        sortable: true,
        column: 'id_code'
    },
    locked: {
        show: false,
        title: i18n.t('message.locked'),
        sortable: false,
        column: 'locked'
    },
    last_login: {
        show: false,
        title: i18n.t('message.last_login'),
        sortable: true,
        column: 'last_login'
    },
    specialties: {
        show: false,
        title: i18n.t('message.specialities'),
        sortable: true,
        column: 'specialties'
    },
    owner_branch_id: {
        show: true,
        title: i18n.t('message.owner_branch'),
        sortable: true,
        column: 'owner_branch_id'
    },
    branch_id: {
        show: true,
        title: i18n.t('message.branch'),
        sortable: true,
        column: 'branch_id'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },
    room_id: {
        show: true,
        title: i18n.t('message.rooms_number'),
        sortable: false,
        column: 'room_id'
    }
};
