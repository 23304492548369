export const rules = {
    from: [
        { required: true, message: 'Пожалуйста, выберите начальное время', trigger: 'blur' },
    ],
    to: [
        { required: true, message: 'Пожалуйста, выберите время окончания', trigger: 'blur' },
    ],
    room_id: [
        { required: true, message: 'Пожалуйста, выберите комната', trigger: 'blur' },
    ],
};