import {model} from "./properties/model";
import {filter} from "./properties/filter";
import {sort} from "../properties/sort";
import {pagination} from "../properties/pagination";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, rooms) => (state.list = rooms),
    CLEAR_FILTER: (state) => (state.filter = JSON.parse( JSON.stringify( filter ))),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    SET_MODEL: (state,model) => {
        state.model.id = model.id;
        state.model.name = model.name;
        state.model.room_number = model.room_number;
        state.model.created_at = model.created_at;
        state.model.updated_at = model.updated_at;

        /**
         * Set permissions
         * @type {Array}
         */
        state.permissions = [];
        if (model.permissions){
            for (let key in model.permissions){
                if (model.permissions.hasOwnProperty(key)){
                    let element = model.permissions[key];
                    state.permissions.push(element)
                }
            }
        }
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
        state.permissions = []
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
        state.sort = JSON.parse( JSON.stringify( sort ));
        state.pagination = JSON.parse( JSON.stringify( pagination ));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = '№';
        state.columns.name.title = i18n.t('message.name');
        state.columns.room_number.title = i18n.t('message.number');
        state.columns.created_at.title = i18n.t('message.date');
        state.columns.updated_at.title = i18n.t('message.update_date');
        state.columns.settings.title = i18n.t('message.settings');
    }
};
