import { actions } from './invoice/actions'
import { getters } from './invoice/getters'
import { state } from './invoice/state'
import { mutations } from './invoice/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
