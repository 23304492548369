import request from "./../utils/request";

export function index(params) {
  return request({
    url: "/debtors",
    method: "get",
    params,
  });
}

export function inventory(params) {
  return request({
    url: "/patients/inventory",
    method: "get",
    params,
  });
}

export function show(id) {
  return request({
    url: `/debtorss/${id}`,
    method: "get",
  });
}

export function update(data) {
  return request({
    url: `/patients/${data.id}`,
    method: "put",
    data,
  });
}

export function destroy(id) {
  return request({
    url: `/patients/${id}`,
    method: "delete",
  });
}

export function search(params) {
  return request({
    url: "/patients/patients/search",
    method: "get",
    params,
  });
}


