export const rules = {
    // date_gos: [
    //     { required: true, message: 'Пожалуйста, введите дата госпитализации', trigger: 'blur' },
    // ],
    date_injury: [
        { required: true, message: 'Пожалуйста, введите дата заболеванияи', trigger: 'blur' },
    ],
    diagnos: [
        { required: true, message: 'Пожалуйста, введите предварительный диагноз', trigger: 'blur' },
    ],
    doctor_id: [
        { required: true, message: 'Пожалуйста, введите врач', trigger: 'blur' },
    ],
    branch_id: [
        { required: true, message: 'Пожалуйста, введите отделения', trigger: 'blur' },
    ],
    teperatura: [
        { required: true, message: 'Пожалуйста, введите температура', trigger: 'blur' },
    ],
    status_id: [
        { required: true, message: 'Пожалуйста, введите состояния', trigger: 'blur' },
    ],
    type_hospitalization_id: [
        { required: true, message: 'Пожалуйста, введите тип госпитализации', trigger: 'blur' },
    ],
    purpose_hospitalization_id: [
        { required: true, message: 'Пожалуйста, введите цель госпитализации', trigger: 'blur' },
    ],
    injury_type_id: [   
        { required: true, message: 'Пожалуйста, введите вид травмы', trigger: 'blur' },
    ],
    date_register: [
        { required: true, message: 'Пожалуйста, введите дата регистрации', trigger: 'blur' },
    ],

};
