import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/debts",
        method: "get",
        params,
    });
}

export function inventory(params) {
    return request({
        url: `/debts/inventory`,
        method: "get",
        params,
    })
}