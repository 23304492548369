import {sort} from "../properties/sort";
import {filter} from "./properties/filter";
import {pagination} from "../properties/pagination";
import {model} from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, hospitalRooms) => {
        state.list = hospitalRooms
    },
    SET_DATAROOM: (state, hospitalRooms) => {
        state.dataroom = hospitalRooms
    },
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_INVENTORY: (state,list) => state.inventory = list,
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_MODEL: (state,model) => {
        state.model = model;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
        state.sort = JSON.parse( JSON.stringify( sort ));
        state.pagination = JSON.parse( JSON.stringify( pagination ));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.hospitalRoom.title = i18n.t('message.room');
        state.columns.corps_id.title = i18n.t('message.corps');
        state.columns.class_id.title = i18n.t('message.class');
        state.columns.floor_id.title = i18n.t('message.floor');
        state.columns.created_at.title = i18n.t('message.date');
        state.columns.updated_at.title = i18n.t('message.update_date');
        state.columns.settings.title = i18n.t('message.settings');
    }
};
