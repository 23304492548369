import Layout from '@/layouts'

const patient = {
  path: '/orders',
  component: Layout,
  name: 'orders',
  redirect: '/orders/index',
  children: [
    {
      path: '/orders/index',
      component: () => import('@/views/orders/index'),
      name: 'orders.index',
      meta: { title: 'Типы расхода'}
    },
  ]
}

export default patient