import Layout from '@/layouts'

const paymentType = {
  path: '/shipments',
  component: Layout,
  name: 'shipments',
  redirect: '/shipments/index',
  children: [{
    path: '/shipments/index',
    component: () => import('@/views/shipments/index'),
    name: 'shipments.index',
    meta: {
      title: 'Списания продукция'
    }
  }, ]
}

export default paymentType