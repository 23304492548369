import Layout from '@/layouts'

const paymentType = {
  path: '/stocksSryaWarehouses',
  component: Layout,
  name: 'stocksSryaWarehouses',
  redirect: '/stocksSryaWarehouses/index',
  children: [{
    path: '/stocksSryaWarehouses/index',
    component: () => import('@/views/stocksSryaWarehouses/index'),
    name: 'stocksSryaWarehouses.index',
    meta: {
      title: 'Продукты'
    }
  }, ]
}

export default paymentType