export const filter = {
    id: "",
    user_id: null,
    transactionable_id: "",
    payment_type_id: "",
    price: "",
    currency_id: "",
    rate: "",
    date: "",
    comment: "",
    search: "",
    created_at: "",
    updated_at: "",
};
