export const filter = {
  id: '',
  name: '',
  code: '',
  sku: '',
  price: '',
  currency_id: '',
  category_id: '',
  vat: '',
  measurement_id: '',
  available: '',
  created_at: '',
  updated_at: '',
};