import { i18n } from '@/utils/i18n';

export const columnsForStuckBunk = {
  id: {
    show: true,
    title: '№',
    sortable: true,
    column: 'id'
  },
  name: {
    show: true,
    title: i18n.t('message.name'),
    sortable: true,
    column: 'name'
  },
  warehouse_id: {
    show: true,
    title: i18n.t('message.warehouse'),
    sortable: true,
    column: 'warehouse_id'
  },
  user: {
    show: true,
    title: i18n.t('message.recipient'),
    sortable: true,
    column: 'user'
  },
  price: {
    show: true,
    title: i18n.t('message.price'),
    sortable: true,
    column: 'price'
  },
  serial_number: {
    show: true,
    title: i18n.t('message.serial_number'),
    sortable: true,
    column: 'serial_number'
  },
  code: {
    show: false,
    title: i18n.t('message.code'),
    sortable: true,
    column: 'code'
  },
  category: {
    show: true,
    title: i18n.t('message.group'),
    sortable: true,
    column: 'category_id'
  },
  measurement: {
    show: true,
    title:  i18n.t('message.measurement'),
    sortable: true,
    column: 'measurement_id'
  },
  received: {
    show: true,
    title: i18n.t('message.qty_come'),
    sortable: true,
    column: 'received'
  },
  created_at: {
    show: true,
    title: i18n.t('message.date'),
    sortable: true,
    column: 'created_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }
};